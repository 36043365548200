import { Button, Grid, IconButton, Typography } from '@mui/material';
import { ROUTES, ROUTE_BUILDER } from '../../utils/constants/routes.constants';
import { createProduct, updateProduct, uploadProductVideo } from '@gozoki/api';
import { useNavigate, useOutletContext } from 'react-router-dom';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ButtonOverrides } from '../../utils/theme/overrides.theme';
import LoadingPaper from '../../components/containers/LoadingPaper';
import type { MenuOption } from '../../components/containers/MenuOptions';
import { NEGOCE_TYPES_OPTIONS } from '@gozoki/api-types';
import Page from '../../components/Page';
import type { ProductOutletContext } from './ProductsOutletProvider';
import RadioGroupRhf from '../../components/inputs/RadioGroupRhf';
import SelectRhf from '../../components/inputs/SelectRhf';
import TextFieldRhf from '../../components/inputs/TextFieldRhf';
import { ThemeColors } from '../../utils/theme/colors.theme';
import { _assert } from '@gozoki/tools';
import { fonts } from '../../utils/theme/fonts.theme';
import { getErrorMessageWithSentry } from '../../utils/sentry';
import { useCallback } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useRights } from '../../utils/hooks';
import { useToast } from '../../components/communication/Toast';

const DLCOptions: MenuOption[] = [
    { label: 'jour même', value: '0' },
    { label: 'la veille', value: '1' },
    { label: 'J-2', value: '2' },
    { label: 'J-3', value: '3' },
    { label: 'J-4', value: '4' },
    { label: 'J-5', value: '5' },
];

const yesNoOptions: MenuOption[] = [
    { label: 'Oui', value: 'true' },
    { label: 'Non', value: 'false' },
];

const AddProductPage4 = () => {
    const navigate = useNavigate();
    const isUserAdmin = useRights(['ADMIN']);

    const { productForm, updateMode, productId, isUpdateModeLoading, preparationVideo } =
        useOutletContext<ProductOutletContext>();
    const { control, reset, trigger, getValues } = productForm;
    const [Toast, showToast] = useToast();

    // ***************************************************** //
    //                      HANDLERS                         //
    // ***************************************************** //

    const navigateBack = useCallback(
        () => navigate(ROUTE_BUILDER.updateProductThirdStep(productId)),
        [navigate, productId]
    );

    const { mutate: update, isLoading: isUpdating } = useMutation(updateProduct, {
        onSuccess: () => {
            handleUploadVideo();

            reset();
            navigate(ROUTES.PRODUCTS);
        },
        onError: (error) => {
            showToast({ message: getErrorMessageWithSentry(error), severity: 'error' });
        },
    });

    const { mutate: upload, isLoading: isUploading } = useMutation(createProduct, {
        onSuccess: (data) => {
            // Get the created product ID and upload the video to it

            handleUploadVideo(data.data.value);

            reset();
            navigate(ROUTES.PRODUCTS);
        },
        onError: (error) => {
            showToast({ message: getErrorMessageWithSentry(error), severity: 'error' });
        },
    });

    const { mutate: uploadVideo } = useMutation(uploadProductVideo);

    // ***************************************************** //
    //                      HANDLERS                         //
    // ***************************************************** //

    const cancel = useCallback(() => {
        reset();
        navigate(ROUTES.PRODUCTS);
    }, [reset, navigate]);

    const validateAndUpload = useCallback(async () => {
        // Validate this page
        const valid = await trigger('antiGaspi');

        if (valid) {
            // Send data
            upload(getValues());
        }
    }, [trigger, upload, getValues]);

    const validateAndModify = useCallback(async () => {
        // Validate this page
        const valid = await trigger('antiGaspi');

        if (valid) {
            // Send data
            update({ id: productId, data: getValues() });
        }
    }, [trigger, update, productId, getValues]);

    const handleUploadVideo = useCallback(
        (overrideId?: number) => {
            const toUploadId = overrideId ?? productId;

            if (preparationVideo !== undefined) {
                // File is not undefined : something changed
                _assert(toUploadId, 'Trying to upload video but the product id is undefined');

                uploadVideo({ id: toUploadId, video: preparationVideo });
            }
        },
        [productId, preparationVideo, uploadVideo]
    );

    return (
        <Page sx={{ maxWidth: '50vw', margin: 'auto' }}>
            <Toast />
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <IconButton onClick={navigateBack}>
                    <ArrowBackIcon
                        sx={{
                            color: ThemeColors.BLACK,
                            height: '24px',
                            width: '24px',
                        }}
                    />
                </IconButton>
                <Typography style={{ ...fonts.pageSubtitle, marginLeft: '16px' }} flexGrow={1}>
                    {updateMode ? 'Modifier un produit' : 'Ajouter un produit'}
                </Typography>

                <Button
                    variant="outlined"
                    sx={{ marginRight: '16px', ...ButtonOverrides.blackOutlined }}
                    onClick={cancel}
                >
                    Annuler
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={updateMode ? validateAndModify : validateAndUpload}
                    disabled={isUserAdmin ? isUploading || isUpdating : true}
                >
                    {updateMode ? 'Modifier' : 'Ajouter'}
                </Button>
            </div>
            <LoadingPaper
                sx={{ marginTop: '40px', padding: '24px', paddingBottom: '40px' }}
                loading={isUpdateModeLoading && updateMode}
            >
                <Grid container spacing={4}>
                    <Grid item flex={1}>
                        <Typography mb="8px" style={fonts.inputHeader}>
                            DUN 14
                        </Typography>
                        <TextFieldRhf
                            variant="outlined"
                            control={control}
                            trigger={trigger}
                            name="dun14"
                            placeholder="DUN 14"
                            fullWidth
                        />
                    </Grid>
                    <Grid item flex={1}>
                        <Typography mb="8px" style={fonts.inputHeader}>
                            Code copilot
                        </Typography>
                        <TextFieldRhf
                            variant="outlined"
                            control={control}
                            trigger={trigger}
                            name="copilotCode"
                            placeholder="Code copilot"
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <Grid item flex={1} mt={2}>
                    <Typography mb="8px" style={fonts.inputHeader}>
                        COMPTABILITÉ (négoce interne ou négoce externe ou sushis)
                    </Typography>
                    <SelectRhf
                        options={NEGOCE_TYPES_OPTIONS}
                        control={control}
                        name="negoceType"
                        label="Type de négoce"
                        disableClearable
                        required
                    />
                </Grid>
                <Typography mt="24px" mb="8px" style={fonts.inputHeader}>
                    Afficher comme nouveauté ?
                </Typography>
                <RadioGroupRhf options={yesNoOptions} row control={control} name="isNew" />

                <Typography mt="24px" mb="8px" style={fonts.inputHeader}>
                    Activer ce produit ?
                </Typography>
                <RadioGroupRhf options={yesNoOptions} row control={control} name="isActive" />

                <Grid container spacing={4} sx={{ alignItems: 'flex-end' }}>
                    <Grid item flex={1}>
                        <Typography mb="8px" mt="24px" style={fonts.inputHeader}>
                            Règle anti-gaspi
                        </Typography>
                        <Typography mb="8px" style={fonts.informationSubText}>
                            Le produit sera automatiquement basculé dans la catégorie Anti-gaspi
                            lorsque sa DLC arrivera à :
                        </Typography>

                        <SelectRhf control={control} options={DLCOptions} name="antiGaspi" />
                    </Grid>
                    <Grid item flex={1}>
                        <Typography mb="8px" mt="24px" style={fonts.inputHeader}>
                            Réduction anti-gaspi
                        </Typography>
                        <Typography mb="8px" style={fonts.informationSubText}>
                            Pourcentage de réduction appliqué sur les produits anti-gaspi (arrondi
                            au 50 cts inférieurs)
                        </Typography>

                        <TextFieldRhf
                            variant="outlined"
                            control={control}
                            trigger={trigger}
                            name="agPromo"
                            placeholder="Réduction anti-gaspi"
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </LoadingPaper>
        </Page>
    );
};

export default AddProductPage4;
