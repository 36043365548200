import { Button, Stack, TextField, Typography } from '@mui/material';
import { type MinStockValuesDto, useUpdateOneMinStock } from '@gozoki/api';
import { useCallback, useState } from 'react';

export interface DailySalesGridRowProps {
    storeId: number;
    productReference: string;
    label: string;
    minStockValues: MinStockValuesDto;
    avgValues: number[];
    edit: boolean;
    disabled: boolean;
    onModify: (reference: string) => void;
    onSave: () => void;
}

export const DailySalesGridRow = (props: DailySalesGridRowProps) => {
    const {
        storeId,
        productReference,
        minStockValues,
        avgValues,
        label,
        edit,
        disabled,
        onModify,
        onSave,
    } = props;
    const { mutate: updateMinStock } = useUpdateOneMinStock();
    const [editValues, setEditValues] = useState<MinStockValuesDto>(minStockValues);
    const handleSave = useCallback(() => {
        if (!editValues) return;
        updateMinStock(
            {
                storeId,
                productReference,
                minStockValues: { ...editValues },
            },
            { onSuccess: () => onSave() }
        );
    }, [editValues, updateMinStock, onSave, productReference, storeId]);

    const textFieldStyle = {
        bgcolor: 'white',
    };

    const inputProps = { inputProps: { min: 0 } };

    const handleModify = useCallback(
        (reference: string) => {
            setEditValues(minStockValues);
            onModify(reference);
        },
        [minStockValues, onModify]
    );

    return (
        <Stack borderBottom="1px solid black">
            <Stack flexDirection="row" gap={1} py={2} alignItems="center">
                <Stack flex={2} px={1}>
                    <Typography variant="body1">{label}</Typography>
                </Stack>
                {avgValues.map((value, index) => {
                    return (
                        <Stack
                            flex={1}
                            key={`avg-${value}-${
                                // biome-ignore lint/suspicious/noArrayIndexKey: index is used as a key
                                index
                            }`}
                        >
                            <Typography>{value}</Typography>
                        </Stack>
                    );
                })}
                <Stack flex={2} />
            </Stack>
            <Stack flexDirection="row" gap={1} py={2} alignItems="center" bgcolor="#F6F6F6">
                <Stack flex={2} px={1}>
                    <Typography variant="body2" fontWeight={600}>
                        Stock minimum
                    </Typography>
                </Stack>
                <Stack flex={1}>
                    {edit ? (
                        <TextField
                            sx={textFieldStyle}
                            InputProps={inputProps}
                            value={editValues.mondayMinimumStock}
                            type="number"
                            onChange={(e) =>
                                setEditValues({
                                    ...editValues,
                                    mondayMinimumStock: Number.parseInt(e.target.value, 10),
                                })
                            }
                        />
                    ) : (
                        <Typography>{minStockValues.mondayMinimumStock}</Typography>
                    )}
                </Stack>
                <Stack flex={1}>
                    {edit ? (
                        <TextField
                            sx={textFieldStyle}
                            InputProps={inputProps}
                            value={editValues.tuesdayMinimumStock}
                            type="number"
                            onChange={(e) =>
                                setEditValues({
                                    ...editValues,
                                    tuesdayMinimumStock: Number.parseInt(e.target.value, 10),
                                })
                            }
                        />
                    ) : (
                        <Typography>{minStockValues.tuesdayMinimumStock}</Typography>
                    )}
                </Stack>
                <Stack flex={1}>
                    {edit ? (
                        <TextField
                            sx={textFieldStyle}
                            InputProps={inputProps}
                            value={editValues.wednesdayMinimumStock}
                            type="number"
                            onChange={(e) =>
                                setEditValues({
                                    ...editValues,
                                    wednesdayMinimumStock: Number.parseInt(e.target.value, 10),
                                })
                            }
                        />
                    ) : (
                        <Typography>{minStockValues.wednesdayMinimumStock}</Typography>
                    )}
                </Stack>
                <Stack flex={1}>
                    {edit ? (
                        <TextField
                            sx={textFieldStyle}
                            InputProps={inputProps}
                            value={editValues.thursdayMinimumStock}
                            type="number"
                            onChange={(e) =>
                                setEditValues({
                                    ...editValues,
                                    thursdayMinimumStock: Number.parseInt(e.target.value, 10),
                                })
                            }
                        />
                    ) : (
                        <Typography>{minStockValues.thursdayMinimumStock}</Typography>
                    )}
                </Stack>
                <Stack flex={1}>
                    {edit ? (
                        <TextField
                            sx={textFieldStyle}
                            InputProps={inputProps}
                            value={editValues.fridayMinimumStock}
                            type="number"
                            onChange={(e) =>
                                setEditValues({
                                    ...editValues,
                                    fridayMinimumStock: Number.parseInt(e.target.value, 10),
                                })
                            }
                        />
                    ) : (
                        <Typography>{minStockValues.fridayMinimumStock}</Typography>
                    )}
                </Stack>
                <Stack flex={1}>
                    {edit ? (
                        <TextField
                            sx={textFieldStyle}
                            InputProps={inputProps}
                            value={editValues.saturdayMinimumStock}
                            type="number"
                            onChange={(e) =>
                                setEditValues({
                                    ...editValues,
                                    saturdayMinimumStock: Number.parseInt(e.target.value, 10),
                                })
                            }
                        />
                    ) : (
                        <Typography>{minStockValues.saturdayMinimumStock}</Typography>
                    )}
                </Stack>
                <Stack flex={1}>
                    {edit ? (
                        <TextField
                            sx={textFieldStyle}
                            InputProps={inputProps}
                            value={editValues.sundayMinimumStock}
                            type="number"
                            onChange={(e) =>
                                setEditValues({
                                    ...editValues,
                                    sundayMinimumStock: Number.parseInt(e.target.value, 10),
                                })
                            }
                        />
                    ) : (
                        <Typography>{minStockValues.sundayMinimumStock}</Typography>
                    )}
                </Stack>
                <Stack flex={1}>
                    {edit ? (
                        <TextField
                            sx={textFieldStyle}
                            InputProps={inputProps}
                            value={editValues.minimumStock}
                            type="number"
                            onChange={(e) =>
                                setEditValues({
                                    ...editValues,
                                    minimumStock: Number.parseInt(e.target.value, 10),
                                })
                            }
                        />
                    ) : (
                        <Typography>{minStockValues.minimumStock}</Typography>
                    )}
                </Stack>
                <Stack flex={1}>
                    {edit ? (
                        <TextField
                            sx={textFieldStyle}
                            InputProps={inputProps}
                            value={editValues.weekEndMinimumStock}
                            type="number"
                            onChange={(e) =>
                                setEditValues({
                                    ...editValues,
                                    weekEndMinimumStock: Number.parseInt(e.target.value, 10),
                                })
                            }
                        />
                    ) : (
                        <Typography>{minStockValues.weekEndMinimumStock}</Typography>
                    )}
                </Stack>
                <Stack flex={2} alignItems="center">
                    {edit ? (
                        <Button onClick={handleSave} variant="contained">
                            Enregistrer
                        </Button>
                    ) : (
                        <Button
                            disabled={disabled}
                            onClick={() => handleModify(productReference)}
                            variant="outlined"
                            color="secondary"
                        >
                            Modifier
                        </Button>
                    )}
                </Stack>
            </Stack>
        </Stack>
    );
};
