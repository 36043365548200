import { useSellers } from '@gozoki/api';
import { FormControl, MenuItem, Select } from '@mui/material';
import { useMemo } from 'react';

export interface SellerPickerProps {
    setCurrentSeller: (value: number) => void;
}

export const SellerPicker = (props: SellerPickerProps) => {
    const { setCurrentSeller } = props;
    const { data: sellers } = useSellers();

    const sellerSelectOptions = useMemo(() => {
        if (!sellers) return [];
        const options = sellers
            .filter((seller) => !!seller.establishments?.length)
            .map((seller) => ({
                label: seller.label,
                value: seller.id,
            }));
        options.push({ label: 'Tous les vendeurs', value: 0 });
        return options;
    }, [sellers]);

    return (
        <FormControl fullWidth>
            <Select
                defaultValue={0}
                onChange={(e) => {
                    setCurrentSeller(e.target.value as number);
                }}
            >
                {sellerSelectOptions?.map((seller) => (
                    <MenuItem key={seller.value} value={seller.value}>
                        {seller.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};
