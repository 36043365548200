import { Outlet, useParams } from 'react-router-dom';
import {
    ProductForm,
    fromExistingProduct,
    getDefaultStoreProductMinimumStock,
    useBoutiques,
    useMinimumStock,
    useProduct,
    useProductForm,
} from '@gozoki/api';
import { useCallback, useEffect, useState } from 'react';

import { LoadedProduct } from '@gozoki/api-types';

export interface ProductOutletContext {
    productForm: ReturnType<typeof useProductForm>;

    updateMode: boolean;
    productId: number;
    isUpdateModeLoading: boolean;

    fillForm: (data: ProductForm) => void;

    preparationVideo: File | null | undefined;
    setPreparationVideo: React.Dispatch<React.SetStateAction<File | null>>;
    previewImageName: string | undefined;
}

const ProductsOutletProvider = () => {
    const { id } = useParams(); // Is "undefined" if adding a product, is a number if updating an existing product

    const product = useProduct(parseInt(id ?? '', 10));
    const { data: storeProductMinimumStock } = useMinimumStock(parseInt(id ?? '', 10));
    // Video to be uploaded during creation/edit process.
    // undefined by default : this means that the video will not be reuploaded if it was not changed during the process
    // If null : delete existing
    // If File : upload new
    const [preparationVideo, setPreparationVideo] = useState<File | null | undefined>(undefined);

    // The product form is shared between all pages, including the main page
    // The main page will use the form as a way to pass existing data for product update
    const productForm = useProductForm();

    // Soft reset the form. Calling reset() with args will permanently change the default values,
    // which is not what we want when initializing an update form from existing data
    const fillForm = useCallback(
        (data: ProductForm) => {
            Object.entries(data).forEach(([fieldName, value]) => {
                productForm.setValue(fieldName as keyof ProductForm, value);
            });
            setPreparationVideo(undefined);
        },
        [productForm]
    );
    const boutiques = useBoutiques();

    // If update mode, prefill the form once existing data arrives
    useEffect(() => {
        // Execute an async function inside useEffect
        const fillFormWithExistingProduct = async () => {
            if (product.data) {
                fillForm(
                    await fromExistingProduct(
                        product.data as LoadedProduct,
                        boutiques.data ?? [],
                        storeProductMinimumStock ?? undefined
                    )
                );
            } else if (
                productForm.getValues('storeProductMinimumStock').length === 0 &&
                boutiques.data
            ) {
                /* Fill up the minimum stocks for the boutiques only if it's empty */
                const productMinimumStock = getDefaultStoreProductMinimumStock(boutiques.data);

                productForm.setValue('storeProductMinimumStock', productMinimumStock ?? []);
            }
        };

        fillFormWithExistingProduct();
    }, [
        product.data,
        productForm.setValue,
        productForm.getValues,
        id,
        fillForm,
        boutiques.data,
        storeProductMinimumStock,
        productForm,
    ]);

    return (
        <Outlet
            context={{
                // Form data
                productForm,
                fillForm,

                // Create vs Modify product
                updateMode: id !== undefined,
                productId: parseInt(id ?? '', 10),
                isUpdateModeLoading: id !== undefined && product.isLoading,

                // Store video
                preparationVideo,
                setPreparationVideo,
                previewImageName: product.data?.previewImage?.name,
            }}
        />
    );
};

export default ProductsOutletProvider;
