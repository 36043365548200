import Page from '../../components/Page';
import { Button, Tab, Tabs, Typography } from '@mui/material';
import { useCallback, useState } from 'react';
import { fonts } from '../../utils/theme/fonts.theme';
import { SELLERS_ROUTES } from '../../utils/constants/routes.constants';
import PlusIcon from '../../components/icons/PlusIcon';
import { useNavigate } from 'react-router-dom';
import { useRights } from '../../utils/hooks';
import TabPanel from '../../components/containers/TabPanel';
import { SellersPanel } from './SellersPanel';
import { EstablishmentsPanel } from './EstablishmentsPanel';

export const SellersPage = () => {
    const isAdmin = useRights(['ADMIN']);

    const [currentTab, setCurrentTab] = useState(0);

    const navigate = useNavigate();

    const handleChange = useCallback(
        (_event: React.SyntheticEvent, newValue: number) => {
            setCurrentTab(newValue);
        },
        [setCurrentTab]
    );

    return (
        <Page>
            <div style={{ display: 'flex' }}>
                <Typography style={fonts.pageTitle} flexGrow={1}>
                    Vendeurs
                </Typography>

                {currentTab === 0 && (
                    <Button
                        variant="contained"
                        onClick={() => navigate(SELLERS_ROUTES.ADD_SELLER)}
                        disabled={!isAdmin}
                    >
                        <PlusIcon />
                        Ajouter un vendeur
                    </Button>
                )}
                {currentTab === 1 && (
                    <Button
                        variant="contained"
                        onClick={() => navigate(SELLERS_ROUTES.ADD_ESTABLISHMENT)}
                        disabled={!isAdmin}
                    >
                        <PlusIcon />
                        Ajouter un établissement
                    </Button>
                )}
            </div>

            <Tabs value={currentTab} onChange={handleChange}>
                <Tab label="Vendeurs" />
                <Tab label="Établissements" />
            </Tabs>

            <TabPanel value={currentTab} index={0}>
                <SellersPanel />
            </TabPanel>

            <TabPanel value={currentTab} index={1}>
                <EstablishmentsPanel />
            </TabPanel>
        </Page>
    );
};
