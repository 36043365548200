import { Seller } from '@gozoki/api-types';
import { gozokiApiWithAuth } from '../gozokiApi';

export const fetchSellers = async () => {
    const response = await gozokiApiWithAuth.get<Seller[]>('/sellers');

    return response.data;
};

export const fetchSeller = async (id: number) => {
    const response = await gozokiApiWithAuth.get<Seller>(`/sellers/${id}`);
    return response.data;
};
