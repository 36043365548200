import { useQuery } from '@tanstack/react-query';
import { fetchEstablishment, fetchEstablishments } from '../queries/establishments.queries';

export const useEstablishments = () =>
    useQuery(['establishments'], () => {
        return fetchEstablishments();
    });

export const useEstablishment = (id: number) => {
    return useQuery(['establishment', id], () => {
        if (Number.isNaN(id)) return null;
        return fetchEstablishment(id);
    });
};
