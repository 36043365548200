import { AuthContext } from '../utils/context/auth.context';
import BasicNavLink from './buttons/BasicNavLink';
import { CalendarIcon } from '@mui/x-date-pickers';
// import CameraAltIcon from '@mui/icons-material/CameraAlt';
import CatIcon from '@mui/icons-material/FormatListBulleted';
import DashboardIcon from '@mui/icons-material/Speed';
import { Drawer } from '@mui/material';
import EuroIcon from '@mui/icons-material/Euro';
import NotifIcon from '@mui/icons-material/NotificationsActive';
import ProductIcon from '@mui/icons-material/Fastfood';
import PromoIcon from '@mui/icons-material/Percent';
import { ROUTES } from '../utils/constants/routes.constants';
import RuleIcon from '@mui/icons-material/Rule';
import SellerIcon from '@mui/icons-material/Store';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import StoreIcon from '@mui/icons-material/LocalGroceryStore';
import TodayIcon from '@mui/icons-material/Event';
import UsersIcon from '@mui/icons-material/Group';
import WCIcon from '@mui/icons-material/Apartment';
import { useContext } from 'react';

const MainDrawer = () => {
    const { authenticated, hasRights } = useContext(AuthContext);

    const isAdmin = hasRights(['ADMIN']);
    const isReader = hasRights(['READER']);
    const isStockReader = hasRights(['STOCK_READER']);
    const isAccountingReader = hasRights(['ACCOUNTING_READER']);
    const isQualityAdmin = hasRights(['QUALITY']);
    const isSupportOperator = hasRights(['SUPPORT_OPERATOR']);

    return (
        <Drawer
            variant="permanent"
            anchor="left"
            PaperProps={{
                sx: {
                    width: '256px',
                    paddingTop: '80px',
                },
            }}
        >
            {authenticated && (
                <>
                    {isReader && (
                        <>
                            <BasicNavLink
                                to={ROUTES.DASHBOARD}
                                Icon={DashboardIcon}
                                text="Dashboard"
                            />
                            <BasicNavLink to={ROUTES.TODAY} Icon={TodayIcon} text="Aujourd'hui" />
                            <BasicNavLink
                                to={ROUTES.CARTS}
                                Icon={ShoppingCartIcon}
                                text="Paniers"
                            />
                            <BasicNavLink to={ROUTES.CATEGORIES} Icon={CatIcon} text="Catégories" />
                            <BasicNavLink to={ROUTES.PRODUCTS} Icon={ProductIcon} text="Produits" />
                            <BasicNavLink
                                to={ROUTES.CLIENTS}
                                Icon={UsersIcon}
                                text="Utilisateurs"
                            />
                            <BasicNavLink
                                to={ROUTES.PROMO_CAMPAIGN}
                                Icon={PromoIcon}
                                text="Campagne promo"
                            />

                            <BasicNavLink
                                to={ROUTES.ENTREPRISES}
                                Icon={WCIcon}
                                text="Partenariats CE"
                            />
                        </>
                    )}

                    {isAdmin && (
                        <BasicNavLink
                            to={ROUTES.NOTIFICATIONS}
                            Icon={NotifIcon}
                            text="Notifications"
                        />
                    )}

                    {isStockReader && (
                        <BasicNavLink to={ROUTES.STORES} Icon={StoreIcon} text="Stocks" />
                    )}

                    {isAccountingReader && (
                        <BasicNavLink to={ROUTES.ACCOUNTING} Icon={EuroIcon} text="Comptabilité" />
                    )}

                    {isQualityAdmin && (
                        <BasicNavLink to={ROUTES.QUALITY} Icon={RuleIcon} text="Qualité" />
                    )}
                    {isSupportOperator && (
                        <BasicNavLink
                            to={ROUTES.KIOSKS_CONTROL}
                            Icon={ShoppingCartIcon}
                            text="Contrôle panier"
                        />
                    )}

                    {isAdmin && (
                        <>
                            <BasicNavLink
                                to={ROUTES.DAILY_SALES}
                                Icon={CalendarIcon}
                                text="Ventes journalières"
                            />
                            <BasicNavLink to={ROUTES.SELLERS} Icon={SellerIcon} text="Vendeurs" />
                        </>
                    )}
                    {/* {isAdmin && (
                        <BasicNavLink to={ROUTES.CAMERAS} Icon={CameraAltIcon} text="Cameras" />
                    )} */}
                </>
            )}
        </Drawer>
    );
};

export default MainDrawer;
