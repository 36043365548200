import {
    CategoryScale,
    Chart as ChartJS,
    Filler,
    Legend,
    LineElement,
    LinearScale,
    PointElement,
    Title,
    Tooltip,
} from 'chart.js';
import { DateStateProps, FourDatePicker } from '../../utils/dashboard/fourDatePicker';
import { FormControl, IconButton, MenuItem, Select, Stack, Typography } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { useHoursData, useTimeSpentDatas } from '@gozoki/api';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { DayOfWeek } from '@gozoki/api-types';
import { DayPicker } from '../../utils/dashboard/dayPicker';
import { Line } from 'react-chartjs-2';
import Page from '../../components/Page';
import { StoresPicker } from '../../utils/dashboard/storesPicker';
import { fonts } from '../../utils/theme/fonts.theme';
import styles from '../../assets/styles/DashboardPage.module.css';
import { timeRanges } from '../../utils/dashboard/timeRanges';
import { useNavigate } from 'react-router-dom';

// Enregistrement des composants nécessaires pour ChartJS
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
);

const DashboardHoursPage = () => {
    const navigate = useNavigate();

    const today = new Date();
    const oneMonthBefore = new Date();
    oneMonthBefore.setMonth(today.getMonth() - 1);

    const [currentTimeRange, setCurrentTimeRange] = useState<number>(-1);
    const [currentDateStart, setCurrentDateStart] = useState<Date>(oneMonthBefore);
    const [currentDateEnd, setCurrentDateEnd] = useState<Date>(today);

    const [selectedStores, setSelectedStores] = useState<number[]>([]);
    const [selectedDays, setSelectedDays] = useState<DayOfWeek[]>([
        'monday',
        'tuesday',
        'wednesday',
        'thursday',
        'friday',
        'saturday',
        'sunday',
    ]);
    const [selectedPlage, setSelectedPlage] = useState<'AM' | 'PM' | 'DAY'>('DAY');
    const { data: hoursData } = useHoursData(
        selectedDays,
        selectedStores,
        selectedPlage,
        currentDateStart,
        currentDateEnd
    );
    const { data: timeSpentData } = useTimeSpentDatas(
        selectedDays,
        selectedStores,
        selectedPlage,
        currentDateStart,
        currentDateEnd
    );
    const hoursDataPlusOne = useMemo(() => {
        if (!hoursData || hoursData.length === 0) return [];
        return [
            ...hoursData,
            {
                time: selectedPlage === 'AM' ? '12:00' : '24:00',
                count: hoursData[0].count,
                amount: hoursData[0].amount,
            },
        ]; // make the chart loop (more intuitive for the user)
    }, [hoursData, selectedPlage]);

    const timeSpendDatasPlusOne = useMemo(() => {
        if (!timeSpentData || timeSpentData.length === 0) return [];
        return [
            ...timeSpentData,
            {
                time: selectedPlage === 'AM' ? '12:00' : '24:00',
                averageTimeInDesk: timeSpentData[0].averageTimeInDesk,
                averageTimeInShop: timeSpentData[0].averageTimeInShop,
            },
        ]; // make the chart loop (more intuitive for the user)
    }, [timeSpentData, selectedPlage]);

    const navigateBack = useCallback(() => {
        navigate('/dashboard');
    }, [navigate]);

    // Préparation des données pour le diagramme
    const chartDataCount = useMemo(
        () => ({
            labels: hoursDataPlusOne.map((d) => `${d.time}`),
            datasets: [
                {
                    label: 'Nombre de paniers vendus',
                    data: hoursDataPlusOne.map((d) => d.count),
                    borderColor: 'rgb(75, 192, 192)',
                    backgroundColor: 'rgba(75, 192, 192, 0.2)',
                    fill: true,
                    tension: 0.5,
                },
            ],
        }),
        [hoursDataPlusOne]
    );

    const chartDataAmount = useMemo(
        () => ({
            labels: hoursDataPlusOne.map((d) => `${d.time}`),
            datasets: [
                {
                    label: 'Valeur totale des paniers vendus',
                    data: hoursDataPlusOne.map((d) => d.amount),
                    borderColor: 'rgb(255, 99, 132)',
                    backgroundColor: 'rgba(255, 99, 132, 0.2)',
                    fill: true,
                    tension: 0.5,
                },
            ],
        }),
        [hoursDataPlusOne]
    );

    const chartDataTimeSpent = useMemo(
        () => ({
            labels: timeSpendDatasPlusOne.map((d) => `${d.time}`),
            datasets: [
                {
                    label: 'Temps passé dans le magasin',
                    data: timeSpendDatasPlusOne.map((d) => d.averageTimeInShop),
                    borderColor: 'rgb(75, 192, 192)',
                    backgroundColor: 'rgba(75, 192, 192, 0.2)',
                    fill: true,
                    tension: 0.5,
                },
                {
                    label: 'Temps passé à la caisse',
                    data: timeSpendDatasPlusOne.map((d) => d.averageTimeInDesk),
                    borderColor: 'rgb(255, 99, 132)',
                    backgroundColor: 'rgba(255, 99, 132, 0.2)',
                    fill: true,
                    tension: 0.5,
                },
                {
                    label: 'temps total',
                    data: timeSpendDatasPlusOne.map(
                        (d) => d.averageTimeInDesk + d.averageTimeInShop
                    ),
                    borderColor: 'rgb(255, 99, 132)',
                    backgroundColor: 'rgba(255, 99, 132, 0.2)',
                    fill: true,
                    tension: 0.5,
                },
            ],
        }),
        [timeSpendDatasPlusOne]
    );

    // Options pour le diagramme
    const optionsCount = useMemo(
        () => ({
            plugins: {
                legend: {
                    display: false,
                },
                title: {
                    display: true,
                    text: `Nombre de paniers vendus par tranche de ${
                        selectedPlage !== 'DAY' ? '5' : '10'
                    } minutes`,
                    font: {
                        size: 24, // Adjusts the font size
                    },
                    align: 'start' as const, // Aligns the title to the left
                },
            },
            scales: {
                y: {
                    beginAtZero: true,
                },
            },
            maintainAspectRatio: false,
        }),
        [selectedPlage]
    );

    const optionsAmount = useMemo(
        () => ({
            plugins: {
                responsive: true,
                legend: {
                    display: false,
                },
                title: {
                    display: true,
                    text: `Valeur totale des paniers vendus par tranche de ${
                        selectedPlage !== 'DAY' ? '5' : '10'
                    } minutes (€)`,
                    font: {
                        size: 24, // Adjusts the font size
                    },
                    align: 'start' as const, // Aligns the title to the left
                },
            },
            scales: {
                y: {
                    beginAtZero: true,
                },
            },
            maintainAspectRatio: false,
        }),
        [selectedPlage]
    );

    const timeSpentDatasOptions = useMemo(
        () => ({
            plugins: {
                responsive: true,
                legend: {
                    display: false,
                },
                title: {
                    display: true,
                    text: 'Temps moyen passé en magasin et en caisse',
                    font: {
                        size: 24, // Adjusts the font size
                    },
                    align: 'start' as const, // Aligns the title to the left
                },
            },
            scales: {
                y: {
                    beginAtZero: true,
                },
            },
            maintainAspectRatio: false,
        }),
        []
    );

    const dateState: DateStateProps = useMemo(() => {
        return {
            timeRanges: {
                all: timeRanges.filter((t) => t.value !== 0),
                current: currentTimeRange,
                set: setCurrentTimeRange,
            },
            currentDate: {
                start: currentDateStart,
                setStart: setCurrentDateStart,
                end: currentDateEnd,
                setEnd: setCurrentDateEnd,
            },
        };
    }, [currentTimeRange, currentDateStart, currentDateEnd]);

    return (
        <Page sx={fonts.roboto}>
            <div style={{ display: 'flex', paddingBottom: 20, alignItems: 'center' }}>
                <IconButton onClick={navigateBack}>
                    <ArrowBackIcon
                        sx={{
                            color: 'black',
                            height: '24px',
                            width: '24px',
                        }}
                    />
                </IconButton>
                <Stack>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            height: '100%',
                            flex: 1,
                        }}
                    >
                        <Typography style={{ ...fonts.pageTitle, paddingLeft: 20 }} flexGrow={1}>
                            Données temporelles
                        </Typography>
                    </div>
                </Stack>
            </div>
            <div>
                <Stack gap={1} p={1} flexDirection="row">
                    <FourDatePicker dateState={dateState} />
                    <StoresPicker
                        onlyBoutiques
                        allowAllStores
                        setCurrentStores={(stores) => {
                            setSelectedStores(stores);
                        }}
                        defaultStore={0}
                    />
                    <div style={{ display: 'flex', flex: 2, margin: 6, gap: 10 }}>
                        <DayPicker setCurrentDays={setSelectedDays} />

                        <FormControl fullWidth>
                            <Select
                                onChange={(e) => {
                                    setSelectedPlage(e.target.value as 'AM' | 'PM' | 'DAY');
                                }}
                                defaultValue="DAY"
                            >
                                <MenuItem value="AM">Matin</MenuItem>
                                <MenuItem value="PM">Après-midi</MenuItem>
                                <MenuItem value="DAY">Journée</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </Stack>
            </div>
            <div style={{ minHeight: 400 }} className={styles.smallWhiteCards}>
                <Line data={chartDataCount} options={optionsCount} />
            </div>
            <div style={{ minHeight: 400 }} className={styles.smallWhiteCards}>
                <Line data={chartDataAmount} options={optionsAmount} />
            </div>
            <div style={{ minHeight: 400 }} className={styles.smallWhiteCards}>
                <Line data={chartDataTimeSpent} options={timeSpentDatasOptions} />
            </div>
        </Page>
    );
};

export default DashboardHoursPage;
