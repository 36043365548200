import { FormControl, MenuItem, Select } from '@mui/material';

import { useEstablishments } from '@gozoki/api';
import { useMemo } from 'react';

export interface EstablishmentPickerProps {
    setCurrentEstablishment: (value: number) => void;
    currentSellerId?: number;
    defaultEstablishment?: number;
}

export const EstablishmentPicker = (props: EstablishmentPickerProps) => {
    const { setCurrentEstablishment, currentSellerId, defaultEstablishment } = props;
    const { data: establishments } = useEstablishments();
    const filteredEstablishments = useMemo(() => {
        if (!establishments) return [];
        if (currentSellerId === 0) return [];
        return establishments.filter(
            (establishment) => establishment.seller?.id === currentSellerId
        );
    }, [establishments, currentSellerId]);

    const establishmentSelectOptions = useMemo(() => {
        const options = filteredEstablishments?.map((establishment) => ({
            label: establishment.label,
            value: establishment.id,
        }));
        options.push({ label: 'Tous les établissements', value: 0 });
        return options;
    }, [filteredEstablishments]);

    return (
        <FormControl fullWidth>
            <Select
                defaultValue={defaultEstablishment}
                onChange={(e) => {
                    setCurrentEstablishment(e.target.value as number);
                }}
            >
                {establishmentSelectOptions?.map((establishment) => (
                    <MenuItem key={establishment.value} value={establishment.value}>
                        {establishment.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};
