import { SellerForm } from '../forms/useSellerForm';
import { gozokiApiWithAuth } from '../gozokiApi';

export const createSeller = async (seller: SellerForm) => {
    const response = await gozokiApiWithAuth.post('/sellers', seller);

    return response.data;
};

export const updateSeller = async ({ id, data }: UpdateSellerArgs) => {
    const response = await gozokiApiWithAuth.patch(`/sellers/${id}`, data);
    return response;
};

export const destroySeller = async (id: number) => {
    const response = await gozokiApiWithAuth.delete(`/sellers/${id}`);
    return response;
};

interface UpdateSellerArgs {
    id: number;
    data: Partial<SellerForm>;
}
