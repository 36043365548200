import {
    CAMERAS_ROUTES,
    CAMPAIGNS_ROUTES,
    CATEGORIES_ROUTES,
    DAILY_SALES_ROUTES,
    ENTREPRISES_ROUTES,
    INVENTORIES_ROUTES,
    NOTIFICATIONS_ROUTES,
    PRODUCTS_ROUTES,
    ROUTES,
    SELLERS_ROUTES,
    STORES_ROUTES,
    USERS_ROUTES,
} from '../utils/constants/routes.constants';
import { Route, Routes } from 'react-router-dom';

import AccountingPage from '../pages/accounting/AccountingPage';
import AddBrandPage from '../pages/categories/AddBrandPage';
import AddCategoryPage from '../pages/categories/AddCategoryPage';
import AddEntreprisePage1 from '../pages/entreprises/AddEntreprisePage1';
import AddEntreprisePage2 from '../pages/entreprises/AddEntreprisePage2';
import AddEstablishmentPage from '../pages/sellers/AddEstablishmentPage';
import AddProductPage1 from '../pages/products/AddProductPage1';
import AddProductPage2 from '../pages/products/AddProductPage2';
import AddProductPage3 from '../pages/products/AddProductPage3';
import AddProductPage4 from '../pages/products/AddProductPage4';
import AddProductPage5 from '../pages/products/AddProductPage5';
import AddPromoCampaignPage from '../pages/promoCampaigns/AddPromoCampaignPage';
import AddSellerPage from '../pages/sellers/AddSellerPage';
import AddStorePage from '../pages/stores/AddStorePage';
import AddUserPage from '../pages/users/AddUserPage';
import { AuthContext } from '../utils/context/auth.context';
import CamerasPages from '../pages/cameras/CamerasPages';
import CartsPage from '../pages/carts/CartsPage';
import CategoriesPage from '../pages/categories/CategoriesPage';
import CreateNotificationPage1 from '../pages/notifications/CreateNotificationPage1';
import { DailySalesPage } from '../pages/dailySales/DailySalesPage';
import DashboardHoursPage from '../pages/dashboard/DashboardHoursPage';
import DashboardPage from '../pages/dashboard/DashboardPage';
import EntrepriseOutletProvider from '../pages/entreprises/EntrepriseOutletProvider';
import EntreprisesPage from '../pages/entreprises/EntreprisesPage';
import ImportMinStockPage from '../pages/dailySales/ImportMinStocksPage';
import ImportProductPage from '../pages/products/ImportProductPage';
import { InventoryDetailsPage } from '../pages/inventories/InventoryDetailsPage';
import KiosksControlPage from '../pages/kiosks/KiosksControlPage';
import NotificationsPage from '../pages/notifications/NotificationsPage';
import ProductDetails from '../pages/products/ProductDetails';
import ProductsOutletProvider from '../pages/products/ProductsOutletProvider';
import ProductsPage from '../pages/products/ProductsPage';
import PromoCampaignsPage from '../pages/promoCampaigns/PromoCampaignPage';
import QualityPage from '../pages/quality/QualityPage';
import { SellersPage } from '../pages/sellers/SellersPage';
import StoreDetailsPage from '../pages/stores/StoreDetailsPage';
import StoresPage from '../pages/stores/StoresPage';
import TestCartsPage from '../pages/test-carts/TestCartsPage';
import TodayPage from '../pages/dashboard/TodayDashboard';
import UsersPage from '../pages/users/UsersPage';
import { useContext } from 'react';

const MainRouter = () => {
    const { hasRights } = useContext(AuthContext);

    const isReader = hasRights(['READER']);
    const isStockReader = hasRights(['STOCK_READER']);
    const isAccountingReader = hasRights(['ACCOUNTING_READER']);

    return (
        <Routes>
            {isReader && (
                <>
                    <Route path={ROUTES.DASHBOARD} element={<DashboardPage />} />
                    <Route path={ROUTES.DASHBOARD_HOURS} element={<DashboardHoursPage />} />
                    <Route path={ROUTES.TODAY} element={<TodayPage />} />
                    <Route path={ROUTES.CARTS} element={<CartsPage />} />
                    <Route path={ROUTES.KIOSKS_CONTROL} element={<KiosksControlPage />} />

                    <Route path={ROUTES.CATEGORIES}>
                        <Route index element={<CategoriesPage />} />
                        <Route
                            path={CATEGORIES_ROUTES.ADD_CATEGORY}
                            element={<AddCategoryPage />}
                        />
                        <Route
                            path={CATEGORIES_ROUTES.ADD_SUB_CATEGORY}
                            element={<AddCategoryPage />}
                        />
                        <Route path={CATEGORIES_ROUTES.ADD_BRAND} element={<AddBrandPage />} />
                        <Route
                            path={CATEGORIES_ROUTES.UPDATE_CATEGORY}
                            element={<AddCategoryPage />}
                        />
                        <Route
                            path={CATEGORIES_ROUTES.UPDATE_SUB_CATEGORY}
                            element={<AddCategoryPage />}
                        />
                        <Route path={CATEGORIES_ROUTES.UPDATE_BRAND} element={<AddBrandPage />} />
                    </Route>

                    <Route path={ROUTES.PRODUCTS}>
                        <Route index element={<ProductsPage />} />
                        <Route
                            path={PRODUCTS_ROUTES.ADD_PRODUCT}
                            element={<ProductsOutletProvider />}
                        >
                            <Route
                                path={PRODUCTS_ROUTES.ADD_PRODUCT_FIRST_STEP}
                                element={<AddProductPage1 />}
                            />
                            <Route
                                path={PRODUCTS_ROUTES.ADD_PRODUCT_SECOND_STEP}
                                element={<AddProductPage2 />}
                            />
                            <Route
                                path={PRODUCTS_ROUTES.ADD_PRODUCT_THIRD_STEP}
                                element={<AddProductPage3 />}
                            />
                            <Route
                                path={PRODUCTS_ROUTES.ADD_PRODUCT_FOURTH_STEP}
                                element={<AddProductPage4 />}
                            />
                            <Route
                                path={PRODUCTS_ROUTES.ADD_PRODUCT_FIFTH_STEP}
                                element={<AddProductPage5 />}
                            />
                        </Route>
                        <Route
                            path={PRODUCTS_ROUTES.UPDATE_PRODUCT}
                            element={<ProductsOutletProvider />}
                        >
                            <Route
                                path={PRODUCTS_ROUTES.UPDATE_PRODUCT_FIRST_STEP}
                                element={<AddProductPage1 />}
                            />
                            <Route
                                path={PRODUCTS_ROUTES.UPDATE_PRODUCT_SECOND_STEP}
                                element={<AddProductPage2 />}
                            />
                            <Route
                                path={PRODUCTS_ROUTES.UPDATE_PRODUCT_THIRD_STEP}
                                element={<AddProductPage3 />}
                            />
                            <Route
                                path={PRODUCTS_ROUTES.UPDATE_PRODUCT_FOURTH_STEP}
                                element={<AddProductPage4 />}
                            />
                            <Route
                                path={PRODUCTS_ROUTES.UPDATE_PRODUCT_FIFTH_STEP}
                                element={<AddProductPage5 />}
                            />
                        </Route>
                        <Route
                            path={PRODUCTS_ROUTES.IMPORT_PRODUCTS}
                            element={<ImportProductPage />}
                        />
                        <Route
                            path={PRODUCTS_ROUTES.PRODUCT_DETAILS}
                            element={<ProductDetails />}
                        />
                    </Route>

                    <Route path={ROUTES.CLIENTS}>
                        <Route index element={<UsersPage />} />
                        <Route path={USERS_ROUTES.ADD_USER} element={<AddUserPage />} />
                        <Route path={USERS_ROUTES.UPDATE} element={<AddUserPage />} />
                    </Route>

                    <Route path={ROUTES.PROMO_CAMPAIGN}>
                        <Route index element={<PromoCampaignsPage />} />
                        <Route
                            path={CAMPAIGNS_ROUTES.ADD_CAMPAIGN}
                            element={<AddPromoCampaignPage />}
                        />
                        <Route
                            path={CAMPAIGNS_ROUTES.UPDATE_CAMPAIGN}
                            element={<AddPromoCampaignPage />}
                        />
                    </Route>

                    <Route path={ROUTES.TEST_CARTS}>
                        <Route index element={<TestCartsPage />} />
                    </Route>

                    <Route path={ROUTES.ENTREPRISES}>
                        <Route index element={<EntreprisesPage />} />
                        <Route
                            path={ENTREPRISES_ROUTES.ADD_ENTREPRISE}
                            element={<EntrepriseOutletProvider />}
                        >
                            <Route
                                path={ENTREPRISES_ROUTES.ADD_ENTREPRISE_FIRST_STEP}
                                element={<AddEntreprisePage1 />}
                            />
                            <Route
                                path={ENTREPRISES_ROUTES.ADD_ENTREPRISE_SECOND_STEP}
                                element={<AddEntreprisePage2 />}
                            />
                        </Route>
                        <Route
                            path={ENTREPRISES_ROUTES.UPDATE_ENTREPRISE}
                            element={<EntrepriseOutletProvider />}
                        >
                            <Route
                                path={ENTREPRISES_ROUTES.UPDATE_ENTREPRISE_FIRST_STEP}
                                element={<AddEntreprisePage1 />}
                            />
                            <Route
                                path={ENTREPRISES_ROUTES.UPDATE_ENTREPRISE_SECOND_STEP}
                                element={<AddEntreprisePage2 />}
                            />
                        </Route>
                    </Route>
                    <Route path={ROUTES.QUALITY}>
                        <Route index element={<QualityPage />} />
                    </Route>
                    <Route path={ROUTES.NOTIFICATIONS}>
                        <Route index element={<NotificationsPage />} />
                        <Route
                            path={NOTIFICATIONS_ROUTES.CREATE_NOTIFICATION}
                            element={<CreateNotificationPage1 />}
                        />
                        <Route
                            path={NOTIFICATIONS_ROUTES.DUPLICATE_NOTIFICATION}
                            element={<CreateNotificationPage1 />}
                        />
                        <Route
                            path={NOTIFICATIONS_ROUTES.VIEW_NOTIFICATION}
                            element={<CreateNotificationPage1 />}
                        />
                    </Route>
                    <Route path={CAMERAS_ROUTES.CAMERAS} element={<CamerasPages />} />
                </>
            )}
            {isStockReader && (
                <>
                    <Route path={ROUTES.STORES}>
                        <Route index element={<StoresPage />} />
                        <Route path={STORES_ROUTES.ADD_STORE} element={<AddStorePage />} />
                        <Route path={STORES_ROUTES.UPDATE_STORE} element={<AddStorePage />} />
                        <Route path={STORES_ROUTES.STORE_DETAILS} element={<StoreDetailsPage />} />
                        <Route
                            path={INVENTORIES_ROUTES.INVENTORY_DETAILS}
                            element={<InventoryDetailsPage />}
                        />
                    </Route>
                    <Route path={ROUTES.DAILY_SALES}>
                        <Route index element={<DailySalesPage />} />
                        <Route path={DAILY_SALES_ROUTES.IMPORT} element={<ImportMinStockPage />} />
                    </Route>
                    <Route path={ROUTES.SELLERS}>
                        <Route index element={<SellersPage />} />
                        <Route path={SELLERS_ROUTES.ADD_SELLER} element={<AddSellerPage />} />
                        <Route path={SELLERS_ROUTES.UPDATE_SELLER} element={<AddSellerPage />} />
                        <Route
                            path={SELLERS_ROUTES.ADD_ESTABLISHMENT}
                            element={<AddEstablishmentPage />}
                        />
                        <Route
                            path={SELLERS_ROUTES.UPDATE_ESTABLISHMENT}
                            element={<AddEstablishmentPage />}
                        />
                    </Route>
                </>
            )}
            {isAccountingReader && <Route path={ROUTES.ACCOUNTING} element={<AccountingPage />} />}
        </Routes>
    );
};

export default MainRouter;
