import { Button, TextField } from '@mui/material';
import { useMemo, useState } from 'react';

import { FourDatePicker } from '../../utils/dashboard/fourDatePicker';
import { GridColDef } from '@mui/x-data-grid';
import { ROUTE_BUILDER } from '../../utils/constants/routes.constants';
import SimpleDataGrid from '../../components/containers/SimpleDataGrid';
import StoreCSVExportButton from '../../components/buttons/StoreCSVExportButton';
import { includesLowerCase } from '@gozoki/tools';
import { timeRanges } from '../../utils/dashboard/timeRanges';
import { useNavigate } from 'react-router-dom';
import { useStoreInventories } from '@gozoki/api';

const inventoryColumnsDef: GridColDef[] = [
    {
        field: 'label',
        headerName: 'Nom',
        flex: 1,
    },
    {
        field: 'storeReference',
        headerName: 'Ref',
        flex: 1,
    },
    {
        field: 'status',
        headerName: 'Statut',
        flex: 1,
    },
    {
        field: 'createdAt',
        headerName: 'Créé le',
        flex: 1,
        valueFormatter: ({ value }) => {
            return new Date(value).toLocaleDateString();
        },
    },
];

interface InventoriesTabProps {
    shopId: number;
}

export const InventoriesTab = (props: InventoriesTabProps) => {
    const { shopId } = props;
    const [currentTimeRange, setCurrentTimeRange] = useState<number>(-1);
    const [currentDateStart, setCurrentDateStart] = useState<Date>(new Date());
    const [currentDateEnd, setCurrentDateEnd] = useState<Date>(new Date());
    const [inventorySearch, setInventorySearch] = useState<string>('');
    const { data: inventories, isLoading } = useStoreInventories(shopId);
    const navigate = useNavigate();
    const navigateInventoryDetails = (id: number) => navigate(ROUTE_BUILDER.inventoryDetails(id));
    const dateState = {
        timeRanges: {
            all: timeRanges,
            current: currentTimeRange,
            set: setCurrentTimeRange,
        },
        currentDate: {
            start: currentDateStart,
            setStart: setCurrentDateStart,
            end: currentDateEnd,
            setEnd: setCurrentDateEnd,
        },
    };

    const inventoryRows = useMemo(() => {
        if (inventories === undefined) {
            return [];
        }
        return inventories.filter((inventory) => {
            const creationDate = new Date(inventory.createdAt);
            return (
                includesLowerCase(inventory.label, inventorySearch) &&
                creationDate.getTime() - 24 * 3600 * 1000 >= currentDateStart.getTime() &&
                creationDate.getTime() - 24 * 3600 * 1000 <= currentDateEnd.getTime()
            );
        });
    }, [inventories, inventorySearch, currentDateStart, currentDateEnd]);

    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'row', margin: 20 }}>
                <FourDatePicker dateState={dateState} />
            </div>
            <>
                <div style={{ display: 'flex', alignItems: 'center', padding: '16px' }}>
                    <TextField
                        variant="outlined"
                        label="Recherche"
                        value={inventorySearch}
                        onChange={(e) => setInventorySearch(e.target.value)}
                    />
                    <div style={{ flexGrow: 1 }} />

                    <Button
                        variant="contained"
                        sx={{ height: '36px', marginRight: '16px' }}
                        disabled
                        onClick={() => {
                            return null; // TODO
                        }}
                    >
                        Modifier
                    </Button>
                    <StoreCSVExportButton selectedStores={[]} sx={{ height: '36px' }} />
                </div>
                <SimpleDataGrid
                    columns={inventoryColumnsDef}
                    rows={inventoryRows}
                    loading={isLoading}
                    onRowClick={(row) => navigateInventoryDetails(row.row.id)}
                />
            </>
        </>
    );
};
