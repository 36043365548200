import { useQuery } from '@tanstack/react-query';
import { fetchSeller, fetchSellers } from '../queries/sellers.queries';

export const useSellers = () => useQuery(['sellers'], fetchSellers);

export const useSeller = (id: number | null) => {
    return useQuery(['seller', id], () => {
        if (Number.isNaN(id) || id === null) return null;
        return fetchSeller(id);
    });
};
