export const ROUTES = {
    ROOT: '/',
    LOGIN: '/login',
    DASHBOARD: '/dashboard',
    DASHBOARD_HOURS: '/dashboard/hours',
    TODAY: '/today',
    CATEGORIES: '/categories',
    PRODUCTS: '/products',
    CLIENTS: '/utilisateurs',
    PROMO_CAMPAIGN: '/promo_campaign',
    STORES: '/stores',
    TEST_CARTS: '/test-carts',
    ENTREPRISES: '/entreprises',
    ACCOUNTING: '/comptabilite',
    QUALITY: '/qualite',
    NOTIFICATIONS: '/notifications',
    CARTS: '/paniers',
    KIOSKS_CONTROL: '/controle-paniers',
    CAMERAS: '/cameras',
    DAILY_SALES: '/daily-sales',
    SELLERS: '/vendeur',
} as const;

// Redirect to this route on login
export const DEFAULT_LOGGED_IN_ROUTE = ROUTES.DASHBOARD;

export const CATEGORIES_ROUTES = {
    ADD_BRAND: 'add_brand',
    ADD_CATEGORY: 'add_category',
    ADD_SUB_CATEGORY: 'add_sub_category',
    UPDATE_BRAND: 'update_brand/:id',
    UPDATE_CATEGORY: 'update_category/:id',
    UPDATE_SUB_CATEGORY: 'update_sub_category/:id',
} as const;

export const SELLERS_ROUTES = {
    ADD_SELLER: 'add_seller',
    UPDATE_SELLER: 'update_seller/:id',
    ADD_ESTABLISHMENT: 'add_establishment',
    UPDATE_ESTABLISHMENT: 'update_establishment/:id',
};

export const ROUTE_BUILDER = {
    updateBrand: (id: number) => `update_brand/${id}`,
    updateCategory: (id: number) => `update_category/${id}`,
    updateSubCategory: (id: number) => `update_sub_category/${id}`,
    updateCampaign: (id: number) => `update_campaign/${id}`,

    productDetails: (id: number) => `/products/details/${id}`,

    // Update products
    updateProductFirstStep: (id: number) => `/products/update_product/${id}/first-step`,
    updateProductSecondStep: (id: number) => `/products/update_product/${id}/second-step`,
    updateProductThirdStep: (id: number) => `/products/update_product/${id}/third-step`,
    updateProductFourthStep: (id: number) => `/products/update_product/${id}/fourth-step`,
    updateProductFifthStep: (id: number) => `/products/update_product/${id}/fifth-step`,

    // update works concil
    updateWorksConcilFirstStep: (id: number) => `/entreprises/update_entreprise/${id}/first-step`,
    updateWorksConcilSecondStep: (id: number) => `/entreprises/update_entreprise/${id}/second-step`,

    // Update stores
    updateStore: (id: number) => `update_store/${id}`,
    storeDetails: (id: number) => `store_details/${id}`,

    // Update users
    updateUser: (id: number) => `update_user/${id}`,

    // Duplicate notification
    duplicateNotification: (id: number) => `create_notification/${id}`,

    // View notification
    viewNotification: (id: number) => `view_notification/${id}`,

    // Inventory details
    inventoryDetails: (id: number) => `inventaire/${id}`,

    // Update seller
    updateSeller: (id: number) => `update_seller/${id}`,

    // Update establishment
    updateEstablishment: (id: number) => `update_establishment/${id}`,
} as const;

export const PRODUCTS_ROUTES = {
    ADD_PRODUCT: 'add_product',
    UPDATE_PRODUCT: 'update_product/:id',
    PRODUCT_DETAILS: 'details/:id',

    // Add products
    ADD_PRODUCT_FIRST_STEP: '/products/add_product/first-step',
    ADD_PRODUCT_SECOND_STEP: '/products/add_product/second-step',
    ADD_PRODUCT_THIRD_STEP: '/products/add_product/third-step',
    ADD_PRODUCT_FOURTH_STEP: '/products/add_product/fourth-step',
    ADD_PRODUCT_FIFTH_STEP: '/products/add_product/fifth-step',

    // Update products
    UPDATE_PRODUCT_FIRST_STEP: '/products/update_product/:id/first-step',
    UPDATE_PRODUCT_SECOND_STEP: '/products/update_product/:id/second-step',
    UPDATE_PRODUCT_THIRD_STEP: '/products/update_product/:id/third-step',
    UPDATE_PRODUCT_FOURTH_STEP: '/products/update_product/:id/fourth-step',
    UPDATE_PRODUCT_FIFTH_STEP: '/products/update_product/:id/fifth-step',

    // Import products
    IMPORT_PRODUCTS: 'import_products',
} as const;

export const CAMPAIGNS_ROUTES = {
    ADD_CAMPAIGN: 'add_campaign',
    UPDATE_CAMPAIGN: 'update_campaign/:id',
};

export const STORES_ROUTES = {
    ADD_STORE: 'add_store',
    UPDATE_STORE: 'update_store/:id',
    STORE_DETAILS: 'store_details/:id',
};

export const ENTREPRISES_ROUTES = {
    ADD_ENTREPRISE: 'add_entreprise',
    UPDATE_ENTREPRISE: 'update_entreprise/:id',

    ADD_ENTREPRISE_FIRST_STEP: '/entreprises/add_entreprise/first-step',
    ADD_ENTREPRISE_SECOND_STEP: '/entreprises/add_entreprise/second-step',

    UPDATE_ENTREPRISE_FIRST_STEP: '/entreprises/update_entreprise/:id/first-step',
    UPDATE_ENTREPRISE_SECOND_STEP: '/entreprises/update_entreprise/:id/second-step',
    ENTREPRISE_DETAILS: 'entreprise_details/:id',
};

export const USERS_ROUTES = {
    ADD_USER: '/utilisateurs/add_user',
    UPDATE: '/utilisateurs/update_user/:id',
};

export const NOTIFICATIONS_ROUTES = {
    NOTIFICATION: '/notifications',
    CREATE_NOTIFICATION: 'create_notification',
    DUPLICATE_NOTIFICATION: 'create_notification/:id',
    VIEW_NOTIFICATION: 'view_notification/:id',
};

export const CAMERAS_ROUTES = {
    CAMERAS: '/cameras',
};

export const INVENTORIES_ROUTES = {
    INVENTORY_DETAILS: 'store_details/:storeId/inventaire/:id',
};

export const DAILY_SALES_ROUTES = {
    DAILY_SALES: 'daily_sales',
    IMPORT: 'daily_sales/import',
};
