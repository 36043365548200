import { useMutation, useQuery } from '@tanstack/react-query';
import {
    fetchInventories,
    fetchInventory,
    fetchStoreInventories,
    updateInventoryDescription,
    updateUnScannableProducts,
    updateInventoryStatus,
} from '../queries/inventories.queries';

export const useInventories = () => {
    return useQuery(['inventories'], fetchInventories);
};

export const useInventory = (id: number) => {
    return useQuery(['inventory', id], () => fetchInventory(id));
};

export const useStoreInventories = (id: number) => {
    return useQuery(['storeInventories', id], () => fetchStoreInventories(id));
};

export const useUpdateUnScannableProducts = () => useMutation(updateUnScannableProducts);

export const useUpdateInventoryDescription = () => useMutation(updateInventoryDescription);

export const useUpdateInventoryStatus = () => useMutation(updateInventoryStatus);
