import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    InputLabel,
    MenuItem,
    Select,
    SxProps,
    Theme,
} from '@mui/material';

import { exportStoresRestockingAsCSV } from '@gozoki/api';
import DownloadIcon from '@mui/icons-material/Download';
import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { writeStoreFiles } from '../../utils/excel/writeStoresFiles';
import { getErrorMessageWithSentry } from '../../utils/sentry';
import { useToast } from '../communication/Toast';
import { DAYS_OF_WEEK, DayOfWeek, frenchDaysOfWeek } from '@gozoki/api-types';

export interface StoreRestockCSVExportButtonProps {
    // Style override
    sx?: SxProps<Theme>;
    selectedStore: number;
}

const StoreRestockCSVExportButton = ({ sx, selectedStore }: StoreRestockCSVExportButtonProps) => {
    const [open, setOpen] = useState(false);
    const [Toast, showToast] = useToast();

    const exportRestocking = useMutation(exportStoresRestockingAsCSV, {
        onSuccess: async (dataOnSuccess) => {
            writeStoreFiles(dataOnSuccess, [selectedStore], 'excel');
        },
        onError: (error) => {
            showToast({
                severity: 'error',
                message: getErrorMessageWithSentry(error),
            });
        },
    });

    const handleClose = (day: DayOfWeek | null) => {
        setOpen(false);

        if (day !== null) {
            exportRestocking.mutate({ day, selectedStore });
        }
    };

    const handleOpen = () => {
        setOpen(true);
    };

    return (
        <>
            <Toast />
            <Button
                variant="contained"
                sx={sx}
                onClick={() => {
                    handleOpen();
                }}
                disabled={exportRestocking.isLoading}
            >
                <DownloadIcon />
                Exporter les besoins de réapprovisionnements
            </Button>
            <Dialog
                open={open}
                onClose={() => handleClose(null)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Choisissez quel besoin de réapprovisionnement vous voulez exporter
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Quel besoin de réapprovisionnement voulez-vous exporter ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ flexDirection: 'column' }}>
                    <InputLabel id="day-select-label">Jour</InputLabel>
                    <Select
                        labelId="day-select-label"
                        id="day-select"
                        onChange={(element) => {
                            handleClose(element.target.value as DayOfWeek);
                        }}
                        sx={{ minWidth: 120 }}
                    >
                        {DAYS_OF_WEEK.map((day, index) => (
                            <MenuItem value={day} key={index}>
                                {frenchDaysOfWeek[day]}
                            </MenuItem>
                        ))}
                    </Select>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default StoreRestockCSVExportButton;
