import { Button, Chip, Paper, TextField, Typography } from '@mui/material';
import { PRODUCTS_ROUTES, ROUTE_BUILDER } from '../../utils/constants/routes.constants';
import { destroyProducts, useProducts } from '@gozoki/api';
import { useMemo, useState } from 'react';

import { ButtonOverrides } from '../../utils/theme/overrides.theme';
import { ConfirmResourceDeletionButton } from '../../components/communication/ConfirmDeletionButton';
import { GridColDef } from '@mui/x-data-grid';
import ImportExportIcon from '../../components/icons/ImportExportIcon';
import Page from '../../components/Page';
import PlusIcon from '../../components/icons/PlusIcon';
import SimpleDataGrid from '../../components/containers/SimpleDataGrid';
import { fonts } from '../../utils/theme/fonts.theme';
import { getErrorMessageWithSentry } from '../../utils/sentry';
import { includesLowerCase } from '@gozoki/tools';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { useRights } from '../../utils/hooks';
import { useToast } from '../../components/communication/Toast';

const TAB_WIDTH = 170;
const SMALL_TAB = 100;
const BIGGER_TAB = 230;

// Helper method : create chips from values
const chips = (values: string[]) => {
    return (
        <>
            {values.map((value) => (
                <Chip key={value} label={value} style={{ marginRight: '8px' }} />
            ))}
        </>
    );
};

const productColDefs: GridColDef[] = [
    {
        field: 'reference',
        headerName: 'EAN 13',
        width: TAB_WIDTH,
    },
    {
        field: 'dun14',
        headerName: 'DUN 14',
        width: TAB_WIDTH,
    },
    { field: 'label', headerName: 'Libellé', width: BIGGER_TAB },
    {
        field: 'categories',
        headerName: 'Catégories',
        width: BIGGER_TAB,
        renderCell: (field) => chips(field.value),
    },
    { field: 'brand', headerName: 'Marque', width: TAB_WIDTH },
    {
        field: 'isNew',
        headerName: 'Nouveauté',
        width: SMALL_TAB,
        renderCell: (field) => (field.value === 'oui' ? <Chip label={field.value} /> : null),
    },
    {
        field: 'isActive',
        headerName: 'Activé',
        width: SMALL_TAB,
        renderCell: (field) => (field.value === 'oui' ? <Chip label={field.value} /> : null),
    },
    {
        field: 'antiGaspi',
        headerName: 'Anti-Gaspi',
        width: SMALL_TAB,
    },
    {
        field: 'copilot',
        headerName: 'Copilot',
        width: TAB_WIDTH,
    },
];

const ProductsPage = () => {
    const navigate = useNavigate();
    const products = useProducts();
    const [Toast, showToast] = useToast();

    const isUserAdmin = useRights(['ADMIN']);

    // ****************************************************** //
    //                    PRODUCT VARIABLES                   //
    // ****************************************************** //

    const [productSearch, setProductSearch] = useState('');
    const [productsSelection, setproductsSelection] = useState<number[]>([]);

    // ****************************************************** //
    //           QUERY TO DATAGRID TRANSFORMATION             //
    // ****************************************************** //

    // Transform fetched products into DataGrid format
    const productRows = useMemo(() => {
        if (products.data === undefined) {
            return [];
        }
        return products.data
            .filter((product) => includesLowerCase(product.label, productSearch))
            .map((product) => ({
                id: product.id, // Datagrid requires a unique id field
                reference: product.reference,
                dun14: product.dun14,
                label: product.label,
                categories: product.categories?.map((cat) => cat.name) ?? [],
                brand: product.brand.name,
                isNew: product.isNew ? 'oui' : 'non',
                isActive: product.isActive ? 'oui' : 'non',
                antiGaspi: `J-${product.antiGaspi}`,
                copilot: product.copilotCode,
            }));
    }, [products.data, productSearch]);

    // ****************************************************** //
    //                         MUTATIONS                      //
    // ****************************************************** //

    const deleteProductMut = useMutation(destroyProducts);

    // ****************************************************** //
    //                         HANDLERS                       //
    // ****************************************************** //

    const handleDeleteProduct = () => {
        return deleteProductMut.mutateAsync(productsSelection, {
            onSuccess: () => {
                setproductsSelection([]);
                products.refetch();
            },
            onError: (error) => {
                showToast({
                    severity: 'error',
                    message: getErrorMessageWithSentry(error),
                });
            },
        });
    };

    const navigateAndUpdateProduct = async () => {
        // Navigate to first step with current selected id
        navigate(ROUTE_BUILDER.updateProductFirstStep(productsSelection[0]));
    };

    const navigateToProductDetails = async () => {
        // Navigate to first step with current selected id
        navigate(ROUTE_BUILDER.productDetails(productsSelection[0]));
    };

    const productsDisabled = useMemo(() => {
        return productsSelection.length === 0 || deleteProductMut.isLoading;
    }, [productsSelection, deleteProductMut.isLoading]);

    return (
        <Page>
            <Toast />
            <div style={{ display: 'flex' }}>
                <Typography style={fonts.pageTitle} flexGrow={1}>
                    Produits
                </Typography>

                <Button
                    variant="outlined"
                    color="secondary"
                    sx={{
                        marginRight: '16px',
                        backgroundColor: 'white',
                        ...ButtonOverrides.shadow,
                    }}
                    onClick={() => {
                        navigate(PRODUCTS_ROUTES.ADD_PRODUCT_FIRST_STEP);
                    }}
                    disabled={!isUserAdmin}
                >
                    <PlusIcon orange />
                    Ajouter un produit
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    sx={{
                        marginRight: '16px',
                    }}
                    onClick={() => navigate(PRODUCTS_ROUTES.IMPORT_PRODUCTS)}
                    disabled={!isUserAdmin}
                >
                    <ImportExportIcon />
                    Importer
                </Button>
            </div>
            <Paper
                sx={{
                    marginTop: '16px',
                }}
            >
                <div
                    style={{ display: 'flex', alignItems: 'center', padding: '16px', gap: '16px' }}
                >
                    <TextField
                        variant="outlined"
                        label="Recherche"
                        value={productSearch}
                        onChange={(e) => setProductSearch(e.target.value)}
                    />
                    <div style={{ flexGrow: 1 }} />

                    <ConfirmResourceDeletionButton
                        handleResourceDeletion={handleDeleteProduct}
                        disabled={!isUserAdmin ? true : productsDisabled}
                    />
                    <Button
                        variant="contained"
                        sx={{ height: '36px' }}
                        disabled={productsDisabled}
                        onClick={navigateAndUpdateProduct}
                    >
                        {isUserAdmin ? 'Modifier' : 'Voir'}
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        sx={{ height: '36px' }}
                        disabled={productsDisabled}
                        onClick={navigateToProductDetails}
                    >
                        Voir
                    </Button>
                </div>
                <SimpleDataGrid
                    columns={productColDefs}
                    rows={productRows}
                    loading={products.isLoading || deleteProductMut.isLoading}
                    onRowSelectionModelChange={(newSelection) =>
                        setproductsSelection(newSelection as number[])
                    }
                />
            </Paper>
        </Page>
    );
};

export default ProductsPage;
