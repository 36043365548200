import { Button, Paper, Stack, TextField, Typography } from '@mui/material';

import { fonts } from '../../utils/theme/fonts.theme';
import { useState } from 'react';
import { useUpdateInventoryDescription } from '@gozoki/api';

interface InventoryDescriptionProps {
    inventoryId: number;
    label: string;
    comment: string;
    onValidate?: () => void;
}

export const InventoryDescription = (props: InventoryDescriptionProps) => {
    const { inventoryId, label, comment, onValidate } = props;
    const [newLabel, setNewLabel] = useState(label);
    const [newComment, setNewComment] = useState(comment);
    const { mutate: updateDescription } = useUpdateInventoryDescription();
    return (
        <Paper sx={{ p: 2 }}>
            <Stack gap={2}>
                <Stack flexDirection="row">
                    <Typography style={fonts.pageSubtitle} flexGrow={1}>
                        Description de l'inventaire
                    </Typography>
                    <Button
                        variant="contained"
                        disabled={label === newLabel && comment === newComment}
                        onClick={() => {
                            updateDescription(
                                {
                                    id: inventoryId,
                                    label: newLabel,
                                    comment: newComment,
                                },
                                {
                                    onSuccess: () => {
                                        setTimeout(() => onValidate && onValidate(), 100);
                                    },
                                }
                            );
                        }}
                    >
                        Enregistrer
                    </Button>
                </Stack>
                <TextField
                    onChange={(e) => setNewLabel(e.target.value)}
                    value={newLabel}
                    label="Libellé de l'inventaire"
                    sx={{ width: '100%' }}
                />
                <TextField
                    onChange={(e) => setNewComment(e.target.value)}
                    value={newComment}
                    label="Commentaires"
                    sx={{ width: '100%' }}
                    multiline
                    minRows={4}
                />
            </Stack>
        </Paper>
    );
};
