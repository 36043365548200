import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

const zEstablishmentForm = z.object({
    reference: z
        .string()
        .nonempty({ message: "La référence de l'établissement est requise." })
        .max(30, {
            message: "La référence de l'établissement doit comporter au maximum 30 caractères.",
        }),
    label: z
        .string()
        .nonempty({ message: 'Le libellé est requis.' })
        .max(30, { message: 'Le libellé doit comporter au maximum 30 caractères.' }),

    // Address
    address: z
        .string()
        .nonempty({ message: "L'adresse est requise." })
        .max(100, { message: "L'adresse doit comporter au maximum 100 caractères." }),
    addressAdditionnal: z
        .string()
        .max(100, { message: "L'adresse additionnelle doit comporter au maximum 100 caractères." })
        .nullable(),
    postalCode: z
        .string()
        .nonempty({ message: 'Le code postal est requis.' })
        .max(20, { message: 'Le code postal doit comporter au maximum 20 caractères.' }),
    city: z
        .string()
        .nonempty({ message: 'La ville est requise.' })
        .max(30, { message: 'La ville doit comporter au maximum 30 caractères.' }),

    sellerId: z.number(),
});

export type EstablishmentForm = z.infer<typeof zEstablishmentForm>;

export const useEstablishmentForm = (defaultValues?: Partial<EstablishmentForm>) => {
    const form = useForm<EstablishmentForm>({
        // Validation function
        resolver: zodResolver(zEstablishmentForm),
        defaultValues: {
            reference: '',
            label: '',
            address: '',
            addressAdditionnal: null,
            postalCode: '',
            city: '',
            ...defaultValues,
        },
    });

    return form;
};
