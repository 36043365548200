import { DashboardCard, ProductCard } from '../../utils/dashboard/dashboardCards';
import { ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { useMemo, useState } from 'react';
import { useMultipleProducts, useStoreTodayData } from '@gozoki/api';

import Page from '../../components/Page';
import { StoresPicker } from '../../utils/dashboard/storesPicker';
import { fonts } from '../../utils/theme/fonts.theme';
import { frenchFormat } from '@gozoki/tools';
import styles from '../../assets/styles/DashboardPage.module.css';

const TodayPage = () => {
    const [currentStores, setCurrentStores] = useState<number>(3);

    const { data } = useStoreTodayData(currentStores);

    const [productMode, setProductMode] = useState<'count' | 'ca'>('count');

    const todayCA: number = useMemo(() => {
        if (!data) return 0;
        return data?.todayCA;
    }, [data]);

    const todayNumberOfProducts: number = useMemo(() => {
        if (!data) return 0;
        return data?.todayNbArticles;
    }, [data]);

    const famousProductsMap = useMemo(() => {
        if (!data) return [];
        if (productMode === 'count') return data?.articlesSellTodayMap;
        return data?.articlesSellTodayMapByCa;
    }, [data, productMode]);

    // ********************* GENERATE FAMOUS PRODUCTS ********************* //

    const { data: famousProducts } = useMultipleProducts(famousProductsMap.map((p) => p.id));

    const famousProductsData = useMemo(() => {
        return famousProducts
            ?.map((product) => {
                const productCount = famousProductsMap.filter((p) => p.id === product.id)[0].value;
                const productCa = famousProductsMap.filter((p) => p.id === product.id)[0].value;
                return { ...product, count: productCount, ca: productCa };
            })
            .sort((a, b) => b.count - a.count);
    }, [famousProducts, famousProductsMap]);

    return (
        <Page sx={fonts.roboto}>
            <div style={{ display: 'flex', paddingLeft: 20 }}>
                <Typography style={fonts.pageTitle} flexGrow={1}>
                    Aujourd'hui chez Lucie, les chiffres sur la journée
                </Typography>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', padding: 20 }}>
                <StoresPicker defaultStore={3} setUniqueStore={setCurrentStores} onlyBoutiques />
            </div>
            <div style={{ display: 'flex' }}>
                <DashboardCard
                    label="CA total"
                    bigValue={`${frenchFormat(todayCA)}\u00A0€ TTC`}
                    orange
                />

                <DashboardCard
                    label="Nombre de produits vendus"
                    bigValue={`${frenchFormat(todayNumberOfProducts)}`}
                />
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div className={styles.smallWhiteCards}>
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <p className={styles.famousThingstitle}>Produits les plus vendus</p>
                        <ToggleButtonGroup
                            color="primary"
                            value={productMode}
                            exclusive
                            onChange={(_, value) => setProductMode(value)}
                        >
                            <ToggleButton style={{ padding: 5 }} value="count">
                                Par quantité
                            </ToggleButton>
                            <ToggleButton style={{ padding: 5 }} value="ca">
                                Par CA
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </div>
                    <div className={styles.famousProductsContainer}>
                        {famousProductsData?.map((product) => (
                            <ProductCard
                                key={product.id}
                                product={product}
                                count={product.count}
                                ca={product.count}
                                mode={productMode}
                                maxCount={famousProductsData[0].count}
                                maxCa={famousProductsData[0].ca}
                                totalCount={todayNumberOfProducts}
                                totalCa={todayCA}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </Page>
    );
};

export default TodayPage;
