import { Button, IconButton, Typography } from '@mui/material';
import { FormEvent, useCallback, useEffect } from 'react';
import { createSeller, updateSeller, useSeller, useSellerForm } from '@gozoki/api';
import { useNavigate, useParams } from 'react-router-dom';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ButtonOverrides } from '../../utils/theme/overrides.theme';
import LoadingPaper from '../../components/containers/LoadingPaper';
import Page from '../../components/Page';
import { ROUTES } from '../../utils/constants/routes.constants';
import TextFieldRhf from '../../components/inputs/TextFieldRhf';
import { ThemeColors } from '../../utils/theme/colors.theme';
import { fonts } from '../../utils/theme/fonts.theme';
import { useMutation } from '@tanstack/react-query';
import { useRights } from '../../utils/hooks';

const AddSellerPage = () => {
    const navigate = useNavigate();
    const isUserAdmin = useRights(['ADMIN']);
    const { id } = useParams();
    const updateMode = !Number.isNaN(Number.parseInt(id ?? '', 10));

    // If this is update mode, use isLoading to show a loading indicator before the data arrives
    const { data, isLoading } = useSeller(Number.parseInt(id ?? '', 10));

    const { control, trigger, getValues, setValue } = useSellerForm();

    // Fill the form with initial data once the data is fetched
    useEffect(() => {
        if (data?.label) setValue('label', data?.label);
        if (data?.reference) setValue('reference', data?.reference);
        if (data?.address?.address) setValue('address', data?.address.address);
        if (data?.address?.addressAdditionnal) {
            setValue('addressAdditionnal', data?.address.addressAdditionnal.toString());
        }
        if (data?.address?.postalCode) setValue('postalCode', data?.address.postalCode);
        if (data?.address?.city) setValue('city', data?.address.city);
    }, [
        setValue,
        data?.label,
        data?.reference,
        data?.address?.address,
        data?.address?.addressAdditionnal,
        data?.address?.postalCode,
        data?.address?.city,
    ]);

    const navigateBack = useCallback(
        () => navigate(ROUTES.SELLERS, { state: { currentTab: 0 } }),
        [navigate]
    );

    const { mutate: upload, isLoading: isUploading } = useMutation(createSeller, {
        onSuccess: navigateBack,
    });

    const { mutate: update, isLoading: isUpdating } = useMutation(updateSeller, {
        onSuccess: navigateBack,
    });

    const uploadSeller = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const valid = await trigger(); // Trigger form validation

        if (valid) {
            upload(getValues());
        }
        // If invalid, the invalid fields will be outlined with red
    };

    const uploadAndUpdateSeller = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const valid = await trigger();

        if (valid) {
            update({
                id: Number.parseInt(id!, 10),
                data: getValues(),
            });
        }
    };

    return (
        <Page sx={{ maxWidth: '50vw', margin: 'auto' }}>
            <form onSubmit={updateMode ? uploadAndUpdateSeller : uploadSeller}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton onClick={navigateBack}>
                        <ArrowBackIcon
                            sx={{
                                color: ThemeColors.BLACK,
                                height: '24px',
                                width: '24px',
                            }}
                        />
                    </IconButton>
                    <Typography style={{ ...fonts.pageSubtitle, marginLeft: '16px' }} flexGrow={1}>
                        {updateMode
                            ? `${isUserAdmin ? 'Modifier' : 'Voir'} un vendeur`
                            : `Ajouter un vendeur`}
                    </Typography>

                    <Button
                        variant="outlined"
                        sx={{ marginRight: '16px', ...ButtonOverrides.blackOutlined }}
                        onClick={navigateBack}
                    >
                        Annuler
                    </Button>
                    <Button
                        type="submit"
                        variant="contained"
                        disabled={!isUserAdmin ? true : isUploading || isUpdating}
                    >
                        {updateMode ? 'Modifier' : 'Ajouter'}
                    </Button>
                </div>
                <LoadingPaper
                    sx={{ marginTop: '40px', padding: '24px', paddingBottom: '40px' }}
                    loading={isLoading && updateMode}
                >
                    <Typography mb="8px" style={fonts.inputHeader}>
                        Libellé
                    </Typography>
                    <TextFieldRhf
                        variant="outlined"
                        label="Libellé du vendeur"
                        control={control}
                        trigger={trigger}
                        name="label"
                        fullWidth
                    />
                    <Typography mt="24px" mb="8px" style={fonts.inputHeader}>
                        Référence
                    </Typography>
                    <TextFieldRhf
                        variant="outlined"
                        label="Référence du vendeur"
                        control={control}
                        trigger={trigger}
                        name="reference"
                        fullWidth
                    />
                    <Typography mt="24px" mb="8px" style={fonts.inputHeader}>
                        Addresse
                    </Typography>
                    <TextFieldRhf
                        variant="outlined"
                        label="Addresse du vendeur"
                        control={control}
                        trigger={trigger}
                        name="address"
                        fullWidth
                    />
                    <Typography mt="24px" mb="8px" style={fonts.inputHeader}>
                        Addresse (additionnelle)
                    </Typography>
                    <TextFieldRhf
                        variant="outlined"
                        label="Addresse du vendeur (additionnelle)"
                        control={control}
                        trigger={trigger}
                        name="addressAdditionnal"
                        fullWidth
                    />
                    <Typography mt="24px" mb="8px" style={fonts.inputHeader}>
                        Code postal
                    </Typography>
                    <TextFieldRhf
                        variant="outlined"
                        label="Code postal du vendeur"
                        control={control}
                        trigger={trigger}
                        name="postalCode"
                        fullWidth
                    />
                    <Typography mt="24px" mb="8px" style={fonts.inputHeader}>
                        Ville
                    </Typography>
                    <TextFieldRhf
                        variant="outlined"
                        label="Ville du vendeur"
                        control={control}
                        trigger={trigger}
                        name="city"
                        fullWidth
                    />
                </LoadingPaper>
            </form>
        </Page>
    );
};

export default AddSellerPage;
