/* eslint-disable no-restricted-syntax */
import { DayOfWeek, InternalCategory, StockEntry, Store } from '@gozoki/api-types';

import { DateTime } from 'luxon';
import { centRound } from '@gozoki/tools';
import jsPDF from 'jspdf';
import logo from '../../assets/logos/logo.png';

export type Font = {
    name: string;
    style: string;
    size: number;
    color: string;
};

// define fonts
const classicBold = {
    name: 'helvetica',
    style: 'bold',
    size: 10,
    color: 'black',
};

const bigTitle = {
    name: 'helvetica',
    style: 'bold',
    size: 18,
    color: 'black',
};

const bigSubtitle = {
    name: 'helvetica',
    style: 'bold',
    size: 14,
    color: 'grey',
};

const classic = {
    name: 'helvetica',
    style: 'normal',
    size: 10,
    color: 'black',
};

const classicGrey = {
    name: 'helvetica',
    style: 'normal',
    size: 9,
    color: 'grey',
};

const small = {
    name: 'helvetica',
    style: 'normal',
    size: 8,
    color: 'black',
};

const setFont = (doc: jsPDF, font: Font) => {
    doc.setFont(font.name, font.style);
    doc.setFontSize(font.size);
    if (font.color === 'grey') doc.setTextColor(128);
    else doc.setTextColor(0);
};

const productSpaces = {
    label: 15,
    ean13: 70,
    dun14: 100,
    poids: 130,
    articles: 155,
    colis: 180,
};

export const getMinStock = (
    map: { [key: string]: number | null },
    day: DayOfWeek,
    weekEndValue: number,
    defaultValue: number
) => {
    if (map[day]) return map[day];
    if (['saturday', 'sunday'].includes(day)) return weekEndValue;
    return defaultValue;
};

const writeCategoryLine = (doc: jsPDF, y: number, category: InternalCategory | undefined) => {
    setFont(doc, classicBold);
    doc.text(
        category?.name.toUpperCase() || 'SANS CATÉGORIE',
        productSpaces.label + productSpaces.ean13,
        y
    );
};

const writeEndLine = (
    doc: jsPDF,
    y: number,
    totalArticles: number,
    totalColis: number,
    endPage: boolean,
    totalWeight?: number,
    nbCaisses?: number
) => {
    setFont(doc, classicBold);
    doc.text(endPage ? 'Total page : ' : 'Total', productSpaces.label, y);
    doc.text(nbCaisses ? `Caisses : ${nbCaisses}` : '', productSpaces.ean13, y);
    doc.text('', productSpaces.dun14, y);
    doc.text(totalWeight?.toFixed(2).toString() || '', productSpaces.poids, y);
    doc.text(totalArticles.toFixed(2).toString(), productSpaces.articles, y);
    doc.text(totalColis.toFixed(2).toString(), productSpaces.colis, y);
};

const writeProductLine = (
    doc: jsPDF,
    y: number,
    product: StockEntry & { isNewCategory: boolean },
    commandType: 'command' | 'delivery',
    day: DayOfWeek
) => {
    setFont(doc, classicGrey);
    doc.text(product.label.slice(0, 32), productSpaces.label, y);
    doc.text(product.reference.replace(/\s+/g, ''), productSpaces.ean13, y);
    doc.text(product.dun14.replace(/\s+/g, ''), productSpaces.dun14, y);

    const minStock =
        getMinStock(
            product.dailyMinStocksMap,
            day,
            product.weekEndMinimumStock,
            product.minimumStock
        ) ?? 0;

    const articleReaproNeeds = minStock - product.stock + product.agStock;
    const reaproWeight = articleReaproNeeds * (product.weight ?? 0);
    const reaproNeeds = articleReaproNeeds / (product.colisage ?? 1);

    if (commandType === 'command') {
        doc.text((reaproWeight / 1000).toString(), productSpaces.poids, y);
    } else {
        doc.text(
            ((product.stock * (product.weight ?? 0)) / 1000).toString(),
            productSpaces.poids,
            y
        );
    }
    if (commandType === 'command') {
        doc.text(articleReaproNeeds.toString(), productSpaces.articles, y);
    } else {
        doc.text(product.stock.toString(), productSpaces.articles, y);
    }
    if (commandType === 'command') {
        doc.text(reaproNeeds.toString(), productSpaces.colis, y);
    } else {
        doc.text(centRound(product.stock / product.colisage).toString(), productSpaces.colis, y);
    }

    doc.line(10, y + 3, 200, y + 3);
};

// const chunkArray: <T>(array: T[], chunkSize: number) => T[][] = (array, chunkSize) => {
//     let index = 0;
//     const arrayLength = array.length;
//     const tempArray = [];

//     for (index = 0; index < arrayLength; index += chunkSize) {
//         const chunk = array.slice(index, index + chunkSize);
//         tempArray.push(chunk);
//     }

//     return tempArray;
// };

const mapEntries = (stockEntries: StockEntry[]) => {
    const ICMap: { [key: number]: StockEntry[] } = {};
    const internalCategories: InternalCategory[] = [];
    stockEntries.forEach((entry) => {
        if (entry.internalCategories.length > 0) {
            const internalCategory = entry.internalCategories[0];
            if (ICMap[internalCategory.id]) {
                ICMap[internalCategory.id].push(entry);
            } else {
                ICMap[internalCategory.id] = [entry];
                internalCategories.push(internalCategory);
            }
        } else if (ICMap[0]) {
            ICMap[0].push(entry);
        } else {
            ICMap[0] = [entry];
            internalCategories.push({ id: 0, name: 'Sans catégorie' });
        }
    });
    return { map: ICMap, categories: internalCategories };
};

const chunkEntries = (
    entriesMap: { [key: number]: StockEntry[] },
    categories: InternalCategory[],
    chunkSize: number
) => {
    const chunks = [];
    let tempArray = [];
    let numLines = 0;
    for (const category of categories) {
        const categorySize = entriesMap[category.id].length;
        for (let i = 0; i < categorySize; i++) {
            if (numLines === chunkSize || (i === 0 && numLines === chunkSize - 1)) {
                chunks.push(tempArray);
                tempArray = [];
                numLines = 0;
            }
            const stockEntry = entriesMap[category.id][i];
            tempArray.push({ ...stockEntry, isNewCategory: i === 0 });
            if (i === 0) numLines += 2;
            else numLines++;
        }
    }
    if (tempArray.length > 0) {
        chunks.push(tempArray);
    }
    return chunks;
};

export const generatePage = (
    doc: jsPDF,
    page: number,
    totalPage: number,
    reaproNeeds: (StockEntry & { isNewCategory: boolean })[],
    receivingStoreInfos: {
        commandNumber: string;
        label: string;
        address1: string;
        address2: string;
        address3: string;
    },
    shippingStoreInfos: {
        label: string;
        address1: string;
        address2: string;
        address3: string;
    },
    dateDepart: DateTime,
    dateLivraison: DateTime,
    commandType: 'delivery' | 'command'
) => {
    // Titre du document
    const day = dateLivraison.weekdayLong?.toLowerCase() as DayOfWeek;
    setFont(doc, bigTitle);
    doc.text(commandType === 'command' ? 'BON DE COMMANDE' : 'BON DE LIVRAISON', 15, 15);
    setFont(doc, classic);
    doc.text(`(Page ${page})`, 80, 15);

    doc.addImage(logo, 'PNG', 170, 8, 27.5, 16);

    // Informations de la boutique
    setFont(doc, bigSubtitle);
    doc.text(receivingStoreInfos.label, 15, 22);

    // En-têtes des sections

    const vp1 = {
        title: 31,
        value: 36,
    }; // vertical places
    const numM = 15; // margin numéro
    const dateDM = 80; // margin date départ
    const dateLM = 145; // margin date livraison

    setFont(doc, classicBold);
    doc.text('Numéro de bon:', numM, vp1.title);
    doc.text(
        commandType === 'command' ? 'Date de génération du bon:' : 'Date de départ:',
        dateDM,
        vp1.title
    );
    doc.text(
        commandType === 'command' ? 'Date de livraison souhaitée:' : 'Date de livraison:',
        dateLM,
        vp1.title
    );

    // Contenu des sections
    setFont(doc, classicGrey);
    doc.text(receivingStoreInfos.commandNumber, numM, vp1.value);
    doc.text(dateDepart.toFormat('dd/MM/yyyy'), dateDM, vp1.value);
    doc.text(dateLivraison.toFormat('dd/MM/yyyy'), dateLM, vp1.value);

    doc.line(10, 39, 200, 39);

    // Expéditeur & Destinataire

    const expM = commandType === 'command' ? -1000 : 15; // margin expéditeur
    const destM = commandType === 'command' ? 15 : 125; // margin destinataire
    const vp2 = {
        title: 44,
        value: 49,
        address1: 54,
        address2: 59,
        address3: 64,
        info1: 69,
        info2: 74,
        info3: 79,
    }; // vertical places

    // libellés
    setFont(doc, classicBold);
    doc.text('Expéditeur', expM, vp2.title);
    doc.text(commandType === 'command' ? 'Adresse' : 'Destinataire', destM, vp2.title);

    setFont(doc, classicGrey);
    doc.text(shippingStoreInfos.label, expM, vp2.value);
    doc.text('LUCIE, LA CABANE AUTONOME', destM, vp2.value);

    // adresses
    setFont(doc, small);

    doc.text(shippingStoreInfos.address1, expM, vp2.address1);
    doc.text(shippingStoreInfos.address2, expM, vp2.address2);
    doc.text(shippingStoreInfos.address3, expM, vp2.address3);

    doc.text(receivingStoreInfos.address1, destM, vp2.address1);
    doc.text(receivingStoreInfos.address2, destM, vp2.address2);
    doc.text(receivingStoreInfos.address3, destM, vp2.address3);

    // Compléments
    setFont(doc, small);

    doc.text('SIRET: 977 672 732 000012', expM, vp2.info1);
    doc.text('NAF : 4799B', expM, vp2.info2);
    doc.text('N° intracommunautaire : FR11 977 672 732', expM, vp2.info3);

    doc.line(10, 83, 200, 83);

    const startTable = 90;

    // Tableau des produits (en tete)
    setFont(doc, classicBold);

    doc.text('Nom du produit', productSpaces.label, startTable);
    doc.text('EAN 13', productSpaces.ean13, startTable);
    doc.text('DUN 14', productSpaces.dun14, startTable);
    doc.text('Poids (kg)', productSpaces.poids, startTable);
    doc.text('Nb Articles', productSpaces.articles, startTable);
    doc.text('Nb Colis', productSpaces.colis, startTable);

    doc.line(10, startTable + 5, 200, startTable + 5, 'F');

    // Tableau des produits (contenu)
    let y = startTable + 10;

    for (const product of reaproNeeds) {
        if (product.isNewCategory) {
            writeCategoryLine(doc, y, product.internalCategories[0]);
            y += 8;
        }
        writeProductLine(doc, y, product, commandType, day);
        y += 8;
    }

    const totalColis = reaproNeeds.reduce((acc, product) => {
        const minStock =
            getMinStock(
                product.dailyMinStocksMap,
                day,
                product.weekEndMinimumStock,
                product.minimumStock
            ) ?? 0;
        const reaproNeed = minStock - product.stock;
        return acc + (commandType === 'command' ? reaproNeed : product.stock) / product.colisage;
    }, 0);
    const totalArticles = reaproNeeds.reduce((acc, product) => {
        const minStock =
            getMinStock(
                product.dailyMinStocksMap,
                day,
                product.weekEndMinimumStock,
                product.minimumStock
            ) ?? 0;
        const reaproNeed = minStock - product.stock;
        return acc + (commandType === 'command' ? reaproNeed : product.stock);
    }, 0);

    setFont(doc, classicGrey);
    writeEndLine(doc, y, totalArticles, totalColis, true);
    doc.line(10, 272, 200, 272);

    // Pied de page
    setFont(doc, classicGrey);

    doc.text(`Page ${page} sur ${totalPage}`, 15, 284);
    doc.text(' 06 83 24 67 23', 120, 284);
    doc.text('|', 154, 284);
    doc.text('contact@lucie-cabane.fr', 164, 284);
};

export const generatePDF = async (
    reaproNeeds: StockEntry[],
    receivingStore: Store,
    shippingStore: Store | undefined,
    dateDepart: DateTime,
    dateLivraison: DateTime,
    commandType: 'delivery' | 'command',
    commandReference: string,
    nbCaisses?: number
) => {
    // receiving store infos
    const day = dateLivraison.weekdayLong?.toLowerCase() as DayOfWeek;
    const receivingStoreLabel = receivingStore.label ?? '';
    const receivingStoreAddress1 = receivingStore.address.address ?? '';
    const receivingStoreAdress2 = `${receivingStore.address.postalCode ?? ''} ${
        receivingStore.address.city ?? ''
    }`;
    const receivingStoreAdress3 = `${receivingStore.address.addressAdditionnal ?? 'France'}`;

    const shippingStoreLabel = shippingStore?.label ?? '';
    const shippingStoreAddress1 = shippingStore?.address.address ?? '';
    const shippingStoreAddress2 = `${shippingStore?.address.postalCode ?? ''} ${
        shippingStore?.address.city ?? ''
    }`;
    const shippingStoreAddress3 = `${shippingStore?.address.addressAdditionnal ?? 'France'}`;

    const receivingStoreInfos = {
        commandNumber: commandReference,
        label: receivingStoreLabel,
        address1: receivingStoreAddress1,
        address2: receivingStoreAdress2,
        address3: receivingStoreAdress3,
    };

    const shippingStoreInfos = {
        label: shippingStoreLabel,
        address1: shippingStoreAddress1,
        address2: shippingStoreAddress2,
        address3: shippingStoreAddress3,
    };

    // Crée une instance de jsPDF
    // eslint-disable-next-line new-cap
    const doc = new jsPDF();
    doc.setDrawColor(128);

    const { map: entriesMap, categories: internalCategories } = mapEntries(reaproNeeds);
    const pages = chunkEntries(entriesMap, internalCategories, 20);
    for (const [index, page] of pages.entries()) {
        generatePage(
            doc,
            index + 1,
            pages.length,
            page,
            receivingStoreInfos,
            shippingStoreInfos,
            dateDepart,
            dateLivraison,
            commandType
        );
        if (index < pages.length - 1) doc.addPage();
    }

    if (pages.length === 0) {
        generatePage(
            doc,
            1,
            1,
            [],
            receivingStoreInfos,
            shippingStoreInfos,
            dateDepart,
            dateLivraison,
            commandType
        );
    }
    const { totalArticles, totalColis, totalWeight } = reaproNeeds.reduce(
        (acc, product) => {
            const isCommand = commandType === 'command';
            const minStock =
                getMinStock(
                    product.dailyMinStocksMap,
                    day,
                    product.weekEndMinimumStock,
                    product.minimumStock
                ) ?? 0;
            const articles = isCommand ? minStock - product.stock : product.stock;
            const colis = articles / product.colisage;
            const weight = articles * (product.weight ?? 0);

            return {
                totalArticles: acc.totalArticles + articles,
                totalColis: acc.totalColis + colis,
                totalWeight: acc.totalWeight + weight,
            };
        },
        { totalArticles: 0, totalColis: 0, totalWeight: 0 }
    );

    writeEndLine(doc, 269, totalArticles, totalColis, false, totalWeight, nbCaisses);

    // Sauvegarde le document
    doc.save('bdl.pdf');
};
