import { EstablishmentForm } from '../forms/useEstablishmentForm';
import { gozokiApiWithAuth } from '../gozokiApi';

export const createEstablishment = async (data: EstablishmentForm) => {
    const response = await gozokiApiWithAuth.post('/establishments', data);
    return response;
};
export const updateEstablishment = async ({ id, data }: UpdateEstablishmentArgs) => {
    const response = await gozokiApiWithAuth.patch(`/establishments/${id}`, data);
    return response;
};

export const destroyEstablishment = async (id: number) => {
    const response = await gozokiApiWithAuth.delete(`/establishments/${id}`);
    return response;
};

interface UpdateEstablishmentArgs {
    id: number;
    data: Partial<EstablishmentForm>;
}
