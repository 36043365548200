import { Button, TextField } from '@mui/material';
import { GridColDef, GridRowId } from '@mui/x-data-grid';
import { destroySeller, useSellers } from '@gozoki/api';

import { ConfirmResourceDeletionButton } from '../../components/communication/ConfirmDeletionButton';
import { Establishment } from '@gozoki/api-types';
import { ROUTE_BUILDER } from '../../utils/constants/routes.constants';
import SimpleDataGrid from '../../components/containers/SimpleDataGrid';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { useRights } from '../../utils/hooks';
import { useState } from 'react';

const sellersColDefs: GridColDef[] = [
    {
        field: 'label',
        headerName: 'Label',
        flex: 1,
    },
    {
        field: 'reference',
        headerName: 'Référence',
        flex: 1,
        renderCell: ({ value }) => {
            return value ?? <i style={{ color: 'red' }}>Non renseigné</i>;
        },
    },
    {
        field: 'establishments',
        headerName: 'Établissements',
        flex: 3,
        valueFormatter: ({ value }) => value.map((e: Establishment) => e.label).join(', '),
    },
    {
        field: 'address',
        headerName: 'Adresse',
        flex: 2,
        renderCell: ({ value }) => {
            return value ? (
                `${value.address} ${value.city} ${value.postalCode}`
            ) : (
                <i style={{ color: 'red' }}>Non renseigné</i>
            );
        },
    },
];

export const SellersPanel = () => {
    const { data: sellers, isLoading, refetch } = useSellers();

    const navigate = useNavigate();
    const [sellersSearch, setSellersSearch] = useState('');
    const [selectedSellers, setSelectedSellers] = useState<number[]>([]);

    const [selectionModel, setSelectionModel] = useState<GridRowId[]>([]);
    const { mutate: deleteSeller } = useMutation(destroySeller, {
        onSuccess: () => {
            setSelectedSellers([]);
            setSelectionModel([]);
            refetch();
        },
    });

    const handleUpdateSeller = async () => {
        navigate(ROUTE_BUILDER.updateSeller(selectedSellers[0]));
    };

    const isAdmin = useRights(['ADMIN']);

    const handleDeleteSeller = async () => {
        deleteSeller(selectedSellers[0]);
    };

    if (!sellers || isLoading) return <div>Loading</div>;

    return (
        <>
            <div style={{ display: 'flex', alignItems: 'center', padding: '16px' }}>
                <TextField
                    variant="outlined"
                    label="Recherche"
                    value={sellersSearch}
                    onChange={(e) => setSellersSearch(e.target.value)}
                />
                <div style={{ flexGrow: 1 }} />
                <ConfirmResourceDeletionButton
                    handleResourceDeletion={handleDeleteSeller}
                    disabled={!isAdmin}
                />
                <Button
                    variant="contained"
                    sx={{ height: '36px' }}
                    onClick={handleUpdateSeller}
                    disabled={!selectedSellers.length}
                >
                    {isAdmin ? 'Modifier' : 'Voir'}
                </Button>
            </div>
            <SimpleDataGrid
                sx={{
                    '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer': {
                        display: 'none',
                    },
                }}
                rowHeight={100} // For image display
                columns={sellersColDefs}
                rows={sellers}
                loading={isLoading}
                rowSelectionModel={selectionModel}
                onRowSelectionModelChange={(selection) => {
                    if (selection.length > 1) {
                        const selectionSet = new Set(selectionModel);
                        const result = selection.filter((s) => !selectionSet.has(s));
                        setSelectionModel(result);
                        setSelectedSellers([selection[selection.length - 1]] as number[]);
                    } else {
                        setSelectionModel(selection);
                        setSelectedSellers([selection[selection.length - 1]] as number[]);
                    }
                }}
            />
        </>
    );
};
