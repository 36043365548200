import { InventoryDetail, InventorySummary } from '@gozoki/api-types';
import { gozokiApi, gozokiApiWithAuth } from '../gozokiApi';

export const fetchInventories = async () => {
    const response = await gozokiApi.get<InventorySummary[]>('/inventories/');
    return response.data;
};

export const fetchInventory = async (id: number) => {
    const response = await gozokiApiWithAuth.get<InventoryDetail>(`/inventories/${id}`);
    return response.data;
};

export const fetchStoreInventories = async (id: number) => {
    const response = await gozokiApiWithAuth.get<InventorySummary[]>(`/stores/${id}/inventories`);
    return response.data;
};

export const updateUnScannableProducts = async (data: {
    id: number;
    productRef: string;
    count: number;
}) => {
    const { id, productRef, count } = data;
    gozokiApiWithAuth.post<InventoryDetail>(`/inventories/${id}/unscannable-products`, {
        productRef,
        count,
    });
};

export const updateInventoryDescription = async (data: {
    id: number;
    label: string;
    comment: string;
}) => {
    const { id, label, comment } = data;
    gozokiApiWithAuth.post(`inventories/${id}/description`, { label, comment });
};

export const updateInventoryStatus = async (data: {
    id: number;
    status: 'pending' | 'validated' | 'canceled';
}) => {
    const { id, status } = data;
    gozokiApiWithAuth.post(`inventories/${id}/status`, { status });
};
