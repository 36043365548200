import { Establishment } from '@gozoki/api-types';
import { gozokiApiWithAuth } from '../gozokiApi';

export const fetchEstablishments = async () => {
    const response = await gozokiApiWithAuth.get<Establishment[]>('/establishments');
    return response.data;
};

export const fetchEstablishment = async (id: number) => {
    const response = await gozokiApiWithAuth.get<Establishment>(`/establishments/${id}`);
    return response.data;
};
