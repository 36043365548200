import { DAYS_OF_WEEK, DayOfWeek, frenchDaysOfWeek } from '@gozoki/api-types';
import { FormControl, MenuItem, Select } from '@mui/material';

import { useMemo } from 'react';

export interface DayPickerProps {
    setCurrentDays: (value: DayOfWeek[]) => void;
}

export const DayPicker = (props: DayPickerProps) => {
    const { setCurrentDays } = props;

    const dayOptions = useMemo(() => {
        if (!DAYS_OF_WEEK) return [];
        const options: { label: string; value: string }[] = DAYS_OF_WEEK.map((day) => ({
            label: frenchDaysOfWeek[day],
            value: day,
        }));
        options.push({ label: 'Tous les jours', value: 'all' });
        options.push({ label: 'Uniquement les week-end', value: 'week-end' });
        options.push({ label: 'Uniquement la semaine', value: 'week' });
        return options;
    }, []);

    return (
        <FormControl fullWidth>
            <Select
                defaultValue="all"
                onChange={(e) => {
                    if (e.target.value === 'all') {
                        setCurrentDays([
                            'monday',
                            'tuesday',
                            'wednesday',
                            'thursday',
                            'friday',
                            'saturday',
                            'sunday',
                        ]);
                        return;
                    }
                    if (e.target.value === 'week-end') {
                        setCurrentDays(['saturday', 'sunday']);
                        return;
                    }
                    if (e.target.value === 'week') {
                        setCurrentDays(['monday', 'tuesday', 'wednesday', 'thursday', 'friday']);
                        return;
                    }
                    setCurrentDays([e.target.value as DayOfWeek]);
                }}
            >
                {dayOptions?.map((day) => (
                    <MenuItem key={day.value} value={day.value}>
                        {day.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};
