import {
    GridActionsCellItem,
    GridColDef,
    GridRowId,
    GridRowModes,
    GridRowModesModel,
} from '@mui/x-data-grid';
import { Paper, Stack, TextField, Typography } from '@mui/material';

import { InventoryProductRow } from './InventoryDetailsPage';
import SimpleDataGrid from '../../components/containers/SimpleDataGrid';
import { includesLowerCase } from '@gozoki/tools';
import { useState } from 'react';

interface InventoryGridProps {
    isLoading: boolean;
    productRows: InventoryProductRow[];
    handleUnprocessedProductsChange: (reference: string, count: number) => void;
    inventoryStatus: 'pending' | 'validated' | 'canceled';
}

const GridButtonModify = (props: { disabled: boolean }) => {
    const { disabled } = props;
    return (
        <Stack
            border={disabled ? 'none' : '1px solid'}
            borderColor="secondary.main"
            borderRadius={2}
            p={1}
            bgcolor={disabled ? 'lightGray' : 'white'}
        >
            <Typography color={disabled ? 'placeholder' : 'secondary'}>Modifier</Typography>
        </Stack>
    );
};

const GridButtonSave = () => {
    return (
        <Stack p={1} bgcolor="primary.main" borderRadius={2}>
            <Typography color="white">Enregistrer</Typography>
        </Stack>
    );
};

export const InventoryGrid = (props: InventoryGridProps) => {
    const { isLoading, productRows, handleUnprocessedProductsChange, inventoryStatus } = props;
    const [search, setSearch] = useState('');
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
    const [editableRow, setEditableRow] = useState<GridRowId>();
    const [updatedCount, setUpdatedCount] = useState<number>();

    const stockColumnsDefs: GridColDef[] = [
        {
            field: 'reference',
            headerName: 'Réf',
            flex: 1,
            editable: false,
        },
        {
            field: 'label',
            headerName: 'Libellé',
            flex: 2,
            editable: false,
        },
        {
            field: 'theoricalCount',
            headerName: 'Stock théorique',
            flex: 1,
            editable: false,
        },
        {
            field: 'inventoryCount',
            headerName: 'Stock inventaire',
            flex: 1,
            editable: inventoryStatus === 'pending',
            renderCell: (params) => {
                return params.row.inventoryCount + params.row.unscannedCount;
            },
            renderEditCell: (params) => {
                return (
                    <Stack flexDirection="row" alignItems="center">
                        <Typography width="50%" textAlign="center">
                            {params.row.inventoryCount} +{' '}
                        </Typography>
                        <TextField
                            InputProps={{ inputProps: { min: 0, max: 10 } }}
                            type="number"
                            defaultValue={params.row.unscannedCount}
                            onChange={(e) =>
                                Number.parseInt(e.target.value, 10) >= 0 &&
                                setUpdatedCount(Number.parseInt(e.target.value, 10))
                            }
                        />
                    </Stack>
                );
            },
        },
        {
            field: 'delta',
            headerName: 'Delta',
            renderCell: (params) => {
                const delta =
                    params.row.delta > 0 ? `+${params.row.delta}` : `-${params.row.delta}`;
                return delta;
            },
        },
        {
            field: 'price',
            headerName: "Prix d'achat",
            editable: false,
        },
        {
            type: 'actions',
            field: 'Actions',
            headerName: 'Modifier',
            getActions: ({ id, row }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            label="Enregistrer"
                            onClick={async () => {
                                if (updatedCount !== undefined) {
                                    handleUnprocessedProductsChange(row.reference, updatedCount);
                                }
                                setRowModesModel(() => ({
                                    [id]: { mode: GridRowModes.View },
                                }));
                                setUpdatedCount(undefined);
                                setEditableRow(undefined);
                            }}
                            icon={<GridButtonSave />}
                            disabled={inventoryStatus !== 'pending'}
                        />,
                    ];
                }
                return [
                    <GridActionsCellItem
                        label="Modifier"
                        icon={
                            <GridButtonModify
                                disabled={
                                    (!!editableRow && editableRow !== id) ||
                                    inventoryStatus !== 'pending'
                                }
                            />
                        }
                        onClick={() => {
                            if (editableRow) {
                                setRowModesModel(() => ({
                                    [editableRow]: {
                                        mode: GridRowModes.View,
                                    },
                                    [id]: {
                                        mode: GridRowModes.Edit,
                                    },
                                }));
                                setEditableRow(id);
                            } else {
                                setRowModesModel(() => ({
                                    [id]: {
                                        mode: GridRowModes.Edit,
                                    },
                                }));
                                setEditableRow(id);
                            }
                        }}
                        disabled={
                            (!!editableRow && editableRow !== id) || inventoryStatus !== 'pending'
                        }
                    />,
                ];
            },
        },
    ];

    const filteredRows = productRows.filter((row) => includesLowerCase(row.label, search));

    return (
        <Paper
            sx={{
                marginTop: '16px',
            }}
        >
            <div style={{ display: 'flex', alignItems: 'center', padding: '16px' }}>
                <TextField
                    variant="outlined"
                    label="Recherche"
                    value={search}
                    onChange={(e) => {
                        setSearch(e.target.value);
                    }}
                />
                <div style={{ flexGrow: 1 }} />
            </div>
            <SimpleDataGrid
                editMode="row"
                rowModesModel={rowModesModel}
                columns={stockColumnsDefs}
                rows={filteredRows}
                loading={isLoading}
                checkboxSelection={false}
                pageSizeOptions={[10, 20, 50, 100]}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 10,
                            page: 0,
                        },
                    },
                }}
            />
        </Paper>
    );
};
